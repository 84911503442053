import { HttpHeaders } from '@angular/common/http';
import { EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { defer } from 'rxjs';
import { from } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { share } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { NextObserver } from 'rxjs';
//
import { BaseLoopBackApi, LoggerService, LoopBackFilter } from '../../../../sdk';
import { DataSourceService } from '../../../my-common/services/datasource.service';
import { ABaseComponent } from './a-base.component';

export enum FORM_STATE {
  NORMAL,
  COLLAPSED,
  FULL,
  DISABLED,
  SAVING,
  LOADING,
  ERROR,
}

export abstract class ABaseModelLoaderComponent<M> extends ABaseComponent implements OnInit, OnChanges, OnDestroy {
  model: (M & { id: any }) | undefined | null;
  //
  error = null;
  @Output() modelLoadingError: EventEmitter<Error> = new EventEmitter();
  //
  @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();
  @Output() modelIdChange: EventEmitter<number | string | null | undefined> = new EventEmitter();
  //
  @Output() beforeLoading: EventEmitter<number | string | null | undefined> = new EventEmitter();
  @Output() loaded: EventEmitter<M | null | undefined> = new EventEmitter();
  @Output() afterLoaded: EventEmitter<M | null | undefined> = new EventEmitter();
  //
  private $modelId$: BehaviorSubject<number | string | null | undefined> = new BehaviorSubject(null);
  private $model$: BehaviorSubject<M | null | undefined> = new BehaviorSubject(null);
  protected model$: Observable<M | null | undefined> = new Observable();
  private _state: Set<FORM_STATE> = new Set();

  protected constructor(protected logger: LoggerService, protected dss: DataSourceService) {
    super(logger);

    this.setState(FORM_STATE.NORMAL);

    this.buildObservables();
    this.model$.pipe(takeUntil(this.$onDestroy$)).subscribe();
  }

  get stateNormal() {
    return this._state.has(FORM_STATE.NORMAL);
  }

  get stateCollapsed() {
    return this._state.has(FORM_STATE.COLLAPSED);
  }

  get stateFull() {
    return this._state.has(FORM_STATE.FULL);
  }

  get stateDisabled() {
    return this._state.has(FORM_STATE.DISABLED);
  }

  get stateLoading() {
    return this._state.has(FORM_STATE.LOADING);
  }

  get stateSaving() {
    return this._state.has(FORM_STATE.SAVING);
  }

  get stateError() {
    return this._state.has(FORM_STATE.ERROR);
  }

  private _loading = false;
  get loading(): boolean {
    return this._loading;
  }

  @Input()
  set loading(value: boolean) {
    if (value !== this._loading) {
      this._loading = value;
      this.loadingChange.emit(value);
    }
  }

  private _modelId: number | string | null | undefined;
  get modelId() {
    return this._modelId;
  }

  @Input()
  set modelId(value: number | string | null | undefined) {
    if (value !== this._modelId) {
      this._modelId = value;
      this.$modelId$.next(value);
      this.modelIdChange.emit(value);
    }
  }

  get errorMessage(): string {
    return this.error && this.error.message ? this.error.message : this.error;
  }

  protected abstract get ModelClass(): any;

  protected get filter(): LoopBackFilter {
    return {};
  }

  protected customHeaders(headers: HttpHeaders) {
    return headers;
  }

  protected get observeModels(): any[] {
    return [this.ModelClass];
  }

  protected get api(): BaseLoopBackApi {
    return this.dss.getApi(this.ModelClass);
  }

  protected get store(): CustomStore {
    return this.dss.getStore(this.ModelClass);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.dss.modifiedEvent.pipe(takeUntil(this.$onDestroy$)).subscribe(modelName => {
      if (this.observeModels.map(model => model.getModelName()).includes(modelName)) {
        this.refresh();
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.$modelId$.unsubscribe();
    this.$model$.unsubscribe();
  }

  setState(state: FORM_STATE): void {
    const groups: FORM_STATE[][] = [
      [FORM_STATE.ERROR, FORM_STATE.NORMAL],
      [FORM_STATE.COLLAPSED, FORM_STATE.FULL],
    ];

    groups.filter(grp => grp.includes(state)).forEach(grp => grp.forEach(s => this.unsetState(s)));

    this._state.add(state);
  }

  unsetState(state: FORM_STATE): void {
    this._state.delete(state);
  }

  refresh() {
    this.$modelId$.next(this._modelId);
  }

  protected buildObservables(): void {
    const prepareObserver: NextObserver<number | string | null | undefined> = {
      next: (id: number | string | null | undefined) => {
        this.error = null;
        this.loading = true;
        this.beforeLoading.emit(id);
      },
    } as NextObserver<number | string>;

    const finallyObserver: NextObserver<M | null | undefined> = {
      next: (model: M | null | undefined) => {
        this.loading = false;
        this.afterLoaded.emit(model);
      },
    } as NextObserver<M | null | undefined>;

    const handleError = (err): Observable<null> => {
      this.error = err;
      this.modelLoadingError.emit(err);
      return of(null);
    };

    const beforeLoadingFn$ = (
      id: number | string | null | undefined,
    ): Observable<number | string | null | undefined> => {
      // this.onModelLoading(id);
      return from(this.beforeModelLoadingAsync(id)).pipe(
        map(() => id),
        catchError(handleError),
      );
    };

    const afterLoadedFn$ = (model: M | null | undefined): Observable<M | null | undefined> => {
      // this.onModelLoaded(model);
      return from(this.afterModelLoadedAsync(model)).pipe(
        map(() => model),
        catchError(handleError),
      );
    };

    const loadModelFn$ = (id: number | string | null | undefined): Observable<M | null | undefined> =>
      id
        ? this.api.findById(id, this.filter, this.customHeaders).pipe(
            // tap(() => console.log('loadModel')),
            map(model => this.dss.models.fixModelNestedTypes(model)),
            // tap(console.log),
            catchError(handleError),
          )
        : of(null);

    const processLoadingFn$ = (modelId: number | string | null | undefined): Observable<M | null | undefined> =>
      defer(() =>
        of(modelId).pipe(
          // prepare
          tap(prepareObserver),
          switchMap(beforeLoadingFn$),
          //
          switchMap(loadModelFn$),
          tap((model: (M & { id: any }) | null | undefined) => {
            this.model = model;
            this.$model$.next(model);
            this.loaded.emit(model);
          }),
          // finally
          tap(finallyObserver),
          switchMap(afterLoadedFn$),
        ),
      );

    this.model$ = this.$modelId$.asObservable().pipe(
      tap((id: number | string | null | undefined) => (this._modelId = id)),
      switchMap(processLoadingFn$),
      share(),
    );
  }

  protected async beforeModelLoadingAsync(id: number | string | null | undefined): Promise<void> {
    // console.log('onModelLoadingAsync: ', id);
  }

  protected async afterModelLoadedAsync(model: M | null | undefined): Promise<void> {
    // console.log('onModelLoadedAsync: ', model);
  }
}
