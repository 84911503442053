import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { difference } from 'lodash-es';
import identity from 'lodash-es/identity';
import moment from 'moment';
import { from, of } from 'rxjs';
import { catchError, filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { dxStoreUpdateHooks } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { hAll } from '../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { FullNamePipe } from '../../../../shared/modules/ui/pipes/full-name.pipe';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Consumer, ConsumerView, LoggerService, PersAlert, PersAlertApi, PersDevice } from '../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';

@Component({
  selector: 'app-legacy-activity-log',
  templateUrl: './legacy-activity-log.component.html',
  styleUrls: ['./legacy-activity-log.component.scss'],
})
export class LegacyActivityLogComponent extends ABaseComponent implements OnInit {
  ds: DataSource | any[];
  clientDso: any;
  deviceDso: any;

  grid_stateStoring: any;

  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;

  typeSource = [
    'Accidential Alert',
    'Device Set-Up',
    'Emergency Services Requested',
    'Fall',
    'GPS Activated Alert',
    'Testing',
    'Other',
  ];

  selectedDate: Date = moment().subtract(1, 'month').toDate();

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private dialog: MatDialog,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
    private ui: UiService,
    private pusher: PusherService,
    private notification: PushNotificationsService,
    public consumerHelper: ConsumerHelperService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'efb1e0d8-c316-4c2f-a59b-6baa20f0b192',
    };

    this.ds = this.buildDataSource();
    this.clientDso = this.buildClientDataSource();
    this.deviceDso = this.buildDeviceDataSource();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {});
  }

  grid_onSelectionChanged(e) {}
  grid_onContextMenuPreparing(e) {}
  grid_onCellPrepared(e) {
    if (e.rowType === 'data') {
      if (difference(e.data.tags, ['Comment', 'Message', 'Listen In Done']).length === 0) {
        (e.cellElement as HTMLElement).classList.add('cell-gray');
      }

      if (e.data.tags.includes('Alarm')) {
        (e.cellElement as HTMLElement).classList.add('cell-warning');
      }

      if (e.data.tags.includes('Resolution')) {
        (e.cellElement as HTMLElement).classList.add('cell-blue');
      }
    }
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxDateBox',
        options: {
          firstDayOfWeek: 1,
          calendarOptions: {
            maxZoomLevel: 'year',
            minZoomLevel: 'decade',
          },
          value: this.selectedDate,
          displayFormat: 'MMMM yyyy',
          pickerType: 'calendar',
          type: 'date',
          useMaskBehavior: true,
          onValueChanged: this.dateBox_onValueChanged.bind(this),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          text: 'Generate Report',
          hint: 'Generate report for the selected month',
          onClick: () => {
            void this.generateReport$().toPromise();
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          text: 'Reset Selection',
          hint: 'Reset "Include" selection',
          onClick: () => {
            void this.resetInclude$().toPromise();
          },
        },
      },
    );
  }

  private resetInclude$() {
    const m = moment(this.selectedDate).startOf('month');

    return from(
      confirm(
        `All "Include" selection for the selected month [${m.format('YYYY-MM')}] will be reset to false. Are you sure?`,
        'Reset "Include" selection',
      ),
    ).pipe(
      first(),
      filter(identity),
      tap(() => this.ui.showLoading()),
      switchMap(() =>
        this.dss.getApi<PersAlertApi>(PersAlert).updateAll(
          {
            and: [
              { include: true }, //
              { datetime: { gte: m.toDate() } }, //
              { datetime: { lt: m.add(1, 'month').toDate() } },
            ],
          },
          {
            include: false,
          },
        ),
      ),
      catchError(err => of(notify(err.message || err, 'error', 8000))),
      tap(() => this.grid.instance.refresh()),
      tap(() => this.ui.hideLoading()),
      takeUntil(this.$onDestroy$),
    );
  }

  private generateReport$() {
    const m = moment(this.selectedDate).startOf('month');

    const notificationOptions: NotificationOptions = {
      body: 'Report Generation Done!',
      requireInteraction: true,
    };

    this.ui.showLoading();
    return this.pusher
      .rpc('PERS_UPMC_ALERT_REPORT', {
        month: m.format('YYYYMM'),
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message || err, 'error', 8000))),
        tap(() => this.ui.hideLoading()),
        takeUntil(this.$onDestroy$),
      );
  }

  dateBox_onValueChanged(e) {
    this.selectedDate = e.value;

    this.ds = [];

    setTimeout(() => {
      this.ds = this.buildDataSource();
    }, 0);
  }

  private buildDataSource() {
    const m = moment(this.selectedDate).startOf('month');

    const so = this.dss.getStoreOptions(PersAlert, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.useRegExp = true;
    so.noSql = true;
    so.customFilter = {
      where: {
        and: [
          { datetime: { gte: m.toDate() } }, //
          { datetime: { lt: m.add(1, 'month').toDate() } },
        ],
      },
      // include: ['device'],
      fields: { source: false },
    };

    const store: CustomStore = new CustomStore(so);

    dxStoreUpdateHooks(store, async (key: any, values: any) => {
      // console.log('before: ', [key, values]);

      if (oc(values).type()) {
        values.include = true;
      }

      return [key, values];
    });

    return new DataSource({
      store,
      // postProcess: (data: Array<PersAlert>) => {
      //   return data;
      // },
    } as DataSourceOptions);
  }

  private buildClientDataSource() {
    const so = this.dss.getStoreOptions(Consumer, ConsumerView, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = hAll;

    return {
      store: new CustomStore(so),
      sort: [{ selector: 'facility_shortname' }, { selector: 'person_firstname' }, { selector: 'person_lastname' }],
    } as DataSourceOptions;
  }

  private buildDeviceDataSource() {
    const so = this.dss.getStoreOptions(PersDevice, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customFilter = {
      include: [{ consumer: 'person' }],
    };
    so.customHeaders = hAll;
    so.noSql = true;

    return {
      store: new CustomStore(so),
    } as DataSourceOptions;
  }

  consDisplayExpr = (d?: PersDevice, format?): string => {
    return new FullNamePipe(this.config).transform(oc(d).consumer(), format);
  };

  deviceDisplayExpr = (d: PersDevice): string => {
    return d.model;
  };
}
