import * as tslib_1 from "tslib";
import { OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import notify from 'devextreme/ui/notify';
import moment, { utc } from 'moment';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { gqlMongoByKey } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { EmployeeDayService, LoggerService, MobileDevice, MyUtilsApi, VehicleCheckUp, } from '../../../../shared/sdk';
import { HelperService } from '../../../consumer/services/helper.service';
export class DailyChecksDetailsTabFullComponent extends ABaseModelPaneWToolbarComponent {
    constructor(config, logger, ui, helper, dss, dialog, pusher, utilsApi) {
        super(logger, ui, dss);
        this.config = config;
        this.logger = logger;
        this.ui = ui;
        this.helper = helper;
        this.dss = dss;
        this.dialog = dialog;
        this.pusher = pusher;
        this.utilsApi = utilsApi;
        this.showAdd = false;
        this.showEdit = false;
        this.ciFields = [
            [
                'Pre-Ignition Checks',
                [
                    'location',
                    'inspectionExp',
                    'visibleLeaksUnderVehicle',
                    'tiresConditionOk',
                    'exteriorClean',
                    'exteriorBodyDamage',
                    'engineOilLevel',
                    'coolantLevel',
                    'emergencyDoorUnlocked',
                ],
            ],
            [
                'Cabin',
                [
                    'engineStartedWithNoIssues',
                    'warningGaugesOff',
                    'odometer',
                    'fuelLevel',
                    'nextOilChange',
                    'wiperFluidPresent',
                    'wipersConditionOk',
                    'headlightsAndTurnSignalsOk',
                    'hornWorks',
                    'kubotaKeyPresent',
                    'cigaretteLighterWorks',
                    'usbChargerOk',
                    'usbChargingWirePresent',
                    'serviceDoorOperational',
                    'wheelchairLiftWorking',
                    'electricRampWorking',
                    'documentBinder',
                    'wawaCard',
                    'insuranceCardPresent',
                    'registrationCardPresent',
                    'registrationCardExp',
                    'accidentIncidentEnvelope',
                    'driverHasDLonHands',
                    'driverHasDMConHands',
                ],
            ],
            [
                'Equipment',
                [
                    'beltCutterPresent',
                    'firstAidKitPresent',
                    'bloodBornePathogenKitPresent',
                    'fireExtinguisherPresent',
                    'fireExtinguisherExp',
                    'fireExtinguisher2Present',
                    'fireExtinguisher2Exp',
                    'cleaningSuppliesPresent',
                    'sanitizerDispenserWorksFilled',
                    'driverHasPPE',
                    'thermometerPresent',
                    'umbrella',
                    'windshieldBrush',
                    'handheldGpsPresent',
                    'windshieldMountGpsHolder',
                    'ezPassTransporterPresent',
                    'emergencyTrianglesPresent',
                    'jumperCablePresent',
                    'seatBeltExtender',
                    'broomPresent',
                    'snowShovel',
                    'salt',
                    'numberOfWheelchairHooks',
                    'numberOfWheelchairBelts',
                    'stepStoolPresent',
                    'foldableWheelchairPresent',
                    'foldableTransportChairPresent',
                    'stairChairPresent',
                    'stairwayWheelchairCarrierPresent',
                    'stretcherPresent',
                    'interiorClean',
                    'IHaveDisinfectedSurfacesOfContact',
                    'minutesOfEngineWarmUpIdleTime',
                    'heatAcWorking',
                    'frontHeatACWorking',
                    'backHeatACWorking',
                    'wcLiftHandPumpHandlePresent',
                    'issuesNumber',
                ],
            ],
        ];
        this.coFields = [
            [
                '',
                [
                    'location',
                    'shiftWithoutIncidents',
                    'incidentOrAccident',
                    'anybodyGotInjured',
                    'vehicleDamage',
                    'visibleLeaksUnderVehicle',
                    'tiresConditionOk',
                    'exteriorClean',
                    'exteriorBodyDamage',
                    'odometer',
                    'warningGaugesOff',
                    'thermometerPresent',
                    'cleaningSuppliesPresent',
                    'driverHasPPE',
                    'handheldGpsPresent',
                    'windshieldMountGpsHolder',
                    'ezPassTransporterPresent',
                    'numberOfWheelchairHooks',
                    'numberOfWheelchairBelts',
                    'stepStoolPresent',
                    'foldableWheelchairPresent',
                    'foldableTransportChairPresent',
                    'stairChairPresent',
                    'stairwayWheelchairCarrierPresent',
                    'stretcherPresent',
                    'interiorClean',
                    'haveDisinfectedSeveralTime',
                    'haveWashedExterior',
                    'vehicleFueledUpToday',
                    'anyVehicleIssuesToReport',
                    'vehicleLeftLocked',
                    'vehicleKeysLocation',
                    'tabletLocation',
                ],
            ],
        ];
        this.full = true;
        this.caption = 'Full Report';
    }
    ngOnInit() {
        super.ngOnInit();
    }
    get ModelClass() {
        return VehicleCheckUp;
    }
    get filter() {
        return { include: ['vehicle', { employee: ['person'] }, 'user'] };
    }
    beforeModelLoadingAsync(id) {
        const _super = Object.create(null, {
            beforeModelLoadingAsync: { get: () => super.beforeModelLoadingAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.fullDoc = undefined;
            this.descAndReport = undefined;
            this.descAndReportMap = { ci: new Map(), co: new Map() };
            return _super.beforeModelLoadingAsync.call(this, id);
        });
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.modelId) {
                try {
                    this.loading = true;
                    this.fullDoc = yield gqlMongoByKey(this.dss, 'VehicleChecks_view', this.modelId).toPromise();
                    this.descAndReport = yield this.api.getAllFieldsDescriptions(this.modelId).toPromise();
                    this.descAndReportMap = {
                        ci: new Map((this.descAndReport.ci || []).map(itm => [itm.field, itm])),
                        co: new Map((this.descAndReport.co || []).map(itm => [itm.field, itm])),
                    };
                    this.fullDoc._mobileDevice = yield this.dss
                        .getApi(MobileDevice)
                        .findOne({
                        where: {
                            deviceUid: this.model.deviceUid,
                        },
                    })
                        .toPromise();
                    if (this.fullDoc.checkout) {
                        this.fullDoc.checkout._vehicleUsageDuration = utc(this.fullDoc.checkout.completedDateTime).diff(this.fullDoc.startedDateTime, 'minutes');
                    }
                    const _moment = moment(this.model.dateTime);
                    const shiftData = yield this.dss
                        .getApi(EmployeeDayService)
                        .getShiftData(this.model.employeeId, _moment.format('YYYY-MM-DD'), [
                        'PUNCH-IN',
                        'PUNCH-OUT',
                        'PAUSE',
                        'RESUME',
                    ])
                        .toPromise();
                    this.fullDoc._pi = shiftData.pi ? moment(shiftData.pi).toDate() : undefined;
                    this.fullDoc._po = shiftData.po ? moment(shiftData.po).toDate() : undefined;
                    this.fullDoc._shiftDuration =
                        this.fullDoc._po && this.fullDoc._pi ? moment(this.fullDoc._po).diff(this.fullDoc._pi, 'minutes') : undefined;
                }
                finally {
                    this.loading = false;
                }
            }
            return _super.afterModelLoadedAsync.call(this, model);
        });
    }
    get FormComponent() {
        return undefined;
    }
    buildToolbarItems(model = null) {
        super.buildToolbarItems(model);
        this.toolbarItems.push({
            location: 'after',
            widget: 'dxButton',
            options: {
                text: 'Print Report',
                hint: 'Print Report',
                disabled: !model,
                onClick: () => this.printReport(),
            },
        });
    }
    printReport() {
        this.ui.showLoading();
        this.api
            .getReportPdfJob(this.modelId)
            .pipe(switchMap(jobId => this.pusher.requestResponse(jobId)), tap(({ bucket, filePdf, uri }) => window.open(uri)), catchError(err => of(notify(err.message || err, 'error', 8000))), tap(() => {
            this.ui.hideLoading();
        }))
            .toPromise()
            .finally();
    }
}
