import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { LoggerService, MyUtils } from '../../../../shared/sdk';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
export class ScheduleSettingsComponent extends ABaseComponent {
    constructor(logger, common, fb, dss, config, employeeHelper, route, router) {
        super(logger);
        this.logger = logger;
        this.common = common;
        this.fb = fb;
        this.dss = dss;
        this.config = config;
        this.employeeHelper = employeeHelper;
        this.route = route;
        this.router = router;
        this.processing = false;
        this.processingSch = false;
        this.gmailPushAuthUrl = '';
        this.enabledComponents = { autoDispatch: false, mtmTrips: false, router: false };
        this.manifestSettings = { showSubscriptionTrips: false, highlightSubscriptionTrips: false, showDropOffTime: false };
        this.assignedEmail = '';
        this.refresh_token = '';
        this.formSettingsMap = new Map();
        this.formSchedulingSettingsMap = new Map();
        this.displayExpr = (u) => `${this.employeeHelper.displayExpr(u) || u.username} - ${u.email}`;
        this.buildForm();
        this.loadSettings();
    }
    ngOnInit() {
        this.route.queryParams.subscribe((params) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (params.code) {
                const { assignedEmail, refresh_token } = yield this.dss
                    .getApi(MyUtils)
                    .getGmailPushTokenByCode(params.code)
                    .toPromise();
                yield this.dss
                    .getApi(MyUtils)
                    .mongoSaveAutoDispatchSettings({ assignedEmail, refresh_token })
                    .toPromise();
                this.assignedEmail = assignedEmail;
                this.refresh_token = refresh_token;
                this.router.navigate(['/dashboard/settings/auto-dispatch']);
            }
        }));
    }
    formScheduling_ngSubmit(e) {
        e.preventDefault();
        void (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.processingSch = true;
            if (this.formSchedulingSettings.valid) {
                const { startPrepareDuration, finishPrepareDuration, overtimeOptimization } = this.formSchedulingSettings.value;
                const data = {
                    startPrepareDuration,
                    finishPrepareDuration,
                    overtimeOptimization,
                };
                yield this.dss.getApi(MyUtils).mongoSaveAutoSchedulingSettings(data).toPromise();
                this.formSchedulingSettings.markAsPristine();
                notify('Done!', 'success');
            }
            else {
                notify('There are not valid fields', 'warning', 5000);
            }
        }))()
            .catch(err => notify(err.message, 'error', 5000))
            .finally(() => (this.processingSch = false));
        return false;
    }
    form_ngSubmit(e) {
        e.preventDefault();
        void (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.processing = true;
            if (this.form.valid) {
                const { maxTripMileage, maxClientWeight, countiesList, shiftPreparationDuration: shiftPreparation, shiftFinalizationDuration: shiftFinalization, boardingDuration: boarding, offBoardingDuration: offBoarding, replyToEmails, } = this.form.value;
                const data = {
                    maxTripMileage,
                    maxClientWeight,
                    countiesList,
                    duration: { shiftPreparation, shiftFinalization, boarding, offBoarding },
                    replyToEmails,
                };
                yield this.dss.getApi(MyUtils).mongoSaveAutoDispatchSettings(data).toPromise();
                this.form.markAsPristine();
                notify('Done!', 'success');
            }
            else {
                notify('There are not valid fields', 'warning', 5000);
            }
        }))()
            .catch(err => notify(err.message, 'error', 5000))
            .finally(() => (this.processing = false));
        return false;
    }
    reset_onClick(e) {
        setTimeout(() => this.form.reset(this.defaultData));
    }
    resetScheduling_onClick(e) {
        setTimeout(() => this.formSchedulingSettings.reset(this.defaultDataScheduling));
    }
    manifestSettingsChanged() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.dss.getApi(MyUtils).mongoSaveManifestSettings(this.manifestSettings).toPromise();
        });
    }
    enabledComponentsValueChanged() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.dss.getApi(MyUtils).mongoSaveEnabledComponents(this.enabledComponents).toPromise();
        });
    }
    startWatching() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.dss.getApi(MyUtils).setGmailPushToStartWatching(this.refresh_token).toPromise();
        });
    }
    stopWatching() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.dss.getApi(MyUtils).setGmailPushToStopWatching(this.refresh_token).toPromise();
        });
    }
    buildForm() {
        this.formSettingsMap.set('', {
            maxTripMileage: [''],
            maxClientWeight: [''],
            countiesList: [''],
            shiftPreparationDuration: [''],
            shiftFinalizationDuration: [''],
            boardingDuration: [''],
            offBoardingDuration: [''],
            replyToEmails: [''],
        });
        this.form = this.fb.group(this.formSettingsMap.get(''));
        this.defaultData = this.form.value;
        this.formSchedulingSettingsMap.set('', {
            startPrepareDuration: [''],
            finishPrepareDuration: [''],
            overtimeOptimization: [''],
        });
        this.formSchedulingSettings = this.fb.group(this.formSchedulingSettingsMap.get(''));
        this.defaultDataScheduling = this.formSchedulingSettings.value;
    }
    loadSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const _a = (yield gqlMongoLoad(this.dss, 'EnabledComponents', {}, []).pipe().toPromise())[0] || {}, { _id } = _a, ec = tslib_1.__rest(_a, ["_id"]);
            this.enabledComponents = ec;
            const _b = (yield gqlMongoLoad(this.dss, 'ManifestSettings', {}, []).pipe().toPromise())[0] || {}, { _id: id1 } = _b, ms = tslib_1.__rest(_b, ["_id"]);
            this.manifestSettings = ms;
            const _c = (yield gqlMongoLoad(this.dss, 'AutoSchedulingSettings', {}, []).pipe().toPromise())[0] || {}, { _id: id } = _c, autoScheduling = tslib_1.__rest(_c, ["_id"]);
            this.formSchedulingSettings.get('startPrepareDuration').setValue(autoScheduling.startPrepareDuration);
            this.formSchedulingSettings.get('finishPrepareDuration').setValue(autoScheduling.finishPrepareDuration);
            this.formSchedulingSettings.get('overtimeOptimization').setValue(autoScheduling.overtimeOptimization);
            const _d = (yield gqlMongoLoad(this.dss, 'AutoDispatchSettings', {}, []).pipe().toPromise())[0] || { duration: {} }, { duration } = _d, settings = tslib_1.__rest(_d, ["duration"]);
            this.assignedEmail = settings.assignedEmail;
            this.refresh_token = settings.refresh_token;
            this.form.get('maxTripMileage').setValue(settings.maxTripMileage);
            this.form.get('maxClientWeight').setValue(settings.maxClientWeight);
            this.form.get('countiesList').setValue(settings.countiesList);
            if (duration) {
                this.form.get('shiftPreparationDuration').setValue(duration.shiftPreparation);
                this.form.get('shiftFinalizationDuration').setValue(duration.shiftFinalization);
                this.form.get('boardingDuration').setValue(duration.boarding);
                this.form.get('offBoardingDuration').setValue(duration.offBoarding);
            }
            this.form.get('replyToEmails').setValue(settings.replyToEmails);
            this.gmailPushAuthUrl = yield this.dss.getApi(MyUtils).getGmailPushAuthUrl().toPromise();
        });
    }
}
