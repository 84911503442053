<app-content-w-header header="Validate Services">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset" style="">
      <div style="display: flex; align-items: center">
        <dx-date-box #from width="100px" [(value)]="selectedFromValue"></dx-date-box>
        <span>&nbsp;&ndash;&nbsp;</span>
        <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
      </div>

      <dx-select-box
        [dataSource]="facilityDso$ | async"
        displayExpr="name"
        valueExpr="id"
        [(value)]="facilityId"
        placeholder="Facility"
        [showClearButton]="true"
        (onSelectionChanged)="facility_onSelectionChanged($event)"></dx-select-box>

      <dx-select-box
        [dataSource]="['OPEN_DAY', 'CLOSE_DAY']"
        [(value)]="dayServiceMarker"
        [showClearButton]="true"
        placeholder="Trip Day Marker"
        width="120px"></dx-select-box>

      <dx-select-box
        [dataSource]="validationStates"
        displayExpr="t"
        valueExpr="v"
        [(value)]="validationState"
        [showClearButton]="true"
        placeholder="Validation Status"
        width="130px"></dx-select-box>

      <dx-select-box
        [dataSource]="[true, false]"
        [(value)]="hasMissingData"
        [showClearButton]="true"
        placeholder="Has Missing Data"
        width="140px"></dx-select-box>

      <dx-select-box
        [dataSource]="[true, false]"
        [(value)]="possibleIssues"
        [showClearButton]="true"
        placeholder="Possible Issues"
        width="130px"></dx-select-box>

      <!--      <dx-select-box-->
      <!--        [dataSource]="['KEYSTONE', 'AMERIHEALTH', 'PHW', 'UPMC', 'Other']"-->
      <!--        [(value)]="mco"-->
      <!--        [showClearButton]="true"-->
      <!--        placeholder="MCO"-->
      <!--        width="70px"-->
      <!--      ></dx-select-box>-->

      <!--      <dx-select-box-->
      <!--        [dataSource]="['CTS', 'MTM', 'LGTC']"-->
      <!--        [(value)]="broker"-->
      <!--        [showClearButton]="true"-->
      <!--        placeholder="Broker"-->
      <!--        width="80px"-->
      <!--      ></dx-select-box>-->

      <app-employee-drop-down-grid
        [(value)]="driverId"
        [filter]="[]"
        [dataSource]="employeeDso$ | async"
        [grid_columns]="[
          {
            dataField: 'tenantId',
            caption: 'Tenant',
            lookup: {
              dataSource: facilityDso$ | async,
              valueExpr: 'id',
              displayExpr: 'shortname'
            }
          },
          { dataField: 'person_firstname', caption: 'Firstname' },
          { dataField: 'person_lastname', caption: 'Lastname' }
        ]"
        [showClearButton]="true"></app-employee-drop-down-grid>

      <app-consumer-drop-down-grid
        [(value)]="clientId"
        [filter]="[]"
        [dataSource]="consumerDso$ | async"
        [grid_columns]="[
          { dataField: 'mci', caption: 'MCI' },
          {
            dataField: 'tenantId',
            caption: 'Tenant',
            lookup: {
              dataSource: facilityDso$ | async,
              valueExpr: 'id',
              displayExpr: 'shortname'
            }
          },
          { dataField: 'person_firstname', caption: 'Firstname' },
          { dataField: 'person_lastname', caption: 'Lastname' }
        ]"
        [showClearButton]="true"></app-consumer-drop-down-grid>

      <dx-text-box width="100px" [(value)]="mci" placeholder="MCI"></dx-text-box>

      <dx-select-box
        [dataSource]="serviceTypes"
        [(value)]="serviceType"
        [showClearButton]="true"
        placeholder="Service Type"
        width="110px"></dx-select-box>

      <dx-select-box
        [dataSource]="directionMarkers"
        [(value)]="directionMarker"
        [showClearButton]="true"
        placeholder="Direction Marker"
        width="130px"></dx-select-box>

      <dx-select-box
        [dataSource]="[true, false]"
        [(value)]="hasTrip"
        [showClearButton]="true"
        placeholder="Has Auth"
        width="100px"></dx-select-box>

      <!--      <dx-select-box-->
      <!--        [dataSource]="[true, false]"-->
      <!--        [(value)]="hasClaim"-->
      <!--        [showClearButton]="true"-->
      <!--        placeholder="Has Claim"-->
      <!--        width="100px"-->
      <!--      ></dx-select-box>-->

      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter()"></dx-button>
      </div>
    </div>
  </mat-card>

  <dx-data-grid
    class="content"
    height="100%"
    [dataSource]="dso$ | async"
    [stateStoring]="grid_stateStoring"
    [twoWayBindingEnabled]="true"
    [repaintChangesOnly]="true"
    [errorRowEnabled]="false"
    [searchPanel]="{ visible: false }"
    [headerFilter]="{ visible: true }"
    [filterRow]="{ visible: true }"
    [filterPanel]="{ visible: true }"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
    (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
    (onSaved)="grid_onSaved($event)"
    (onSaving)="grid_onSaving($event)"
    (onCellPrepared)="grid_onCellPrepared($event)">
    <dxo-editing
      [allowAdding]="false"
      [allowDeleting]="false"
      [allowUpdating]="true"
      [mode]="'cell'"
      [(changes)]="changes"
      [(editRowKey)]="editRowKey"></dxo-editing>

    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

    <dxo-paging [pageSize]="config?.config?.itemsPerPage || 50"></dxo-paging>

    <dxo-selection [mode]="'multiple'" [selectAllMode]="'page'"></dxo-selection>

    <!--    <dxo-export-->
    <!--      [enabled]="true"-->
    <!--      fileName="claims-export"-->
    <!--    ></dxo-export>-->

    <!-- region Columns -->

    <!--<dxi-column type="selection" [sortIndex]="0"></dxi-column>-->

    <dxi-column caption="Validation Status" dataField="validationState" dataType="string" [allowEditing]="true">
      <dxo-lookup [dataSource]="validationStates" valueExpr="v" displayExpr="t"> </dxo-lookup>
    </dxi-column>

    <dxi-column caption="Day Service Marker" dataField="_dayServiceMarker" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Facility" dataField="tenantId" dataType="string" [allowEditing]="false" [allowSorting]="false">
      <dxo-lookup [dataSource]="facilitySubDso$ | async" valueExpr="id" displayExpr="shortname"> </dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="Date"
      dataField="vdate"
      dataType="date"
      format="shortDate"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      [allowEditing]="false"></dxi-column>

    <dxi-column caption="Client" dataField="consumerId" [allowEditing]="false" [allowSorting]="false">
      <dxo-lookup [dataSource]="consumerSubDso$ | async" valueExpr="id" [displayExpr]="consumerHelper.displayExpr">
      </dxo-lookup>
    </dxi-column>

    <dxi-column caption="MCI" dataField="getMci" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Client Last Name" dataField="getLastName" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Client First Name" dataField="getFirstName" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Employee" dataField="employeeId" [allowEditing]="false" [allowSorting]="false">
      <dxo-lookup [dataSource]="employeeSubDso$ | async" valueExpr="id" [displayExpr]="employeeHelper.displayExpr">
      </dxo-lookup>
    </dxi-column>

    <dxi-column caption="Vehicle" dataField="vehicleId" [allowEditing]="false" [allowSorting]="false">
      <dxo-lookup [dataSource]="vehicleSubDso$ | async" valueExpr="id" displayExpr="internalId"> </dxo-lookup>
    </dxi-column>

    <dxi-column caption="Manifest Rec" dataField="manifestRecId" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Marker" dataField="marker" [allowEditing]="true">
      <dxo-lookup [dataSource]="directionMarkers"></dxo-lookup>
    </dxi-column>

    <!--    -->

    <dxi-column caption="Service Type" dataField="getServiceType" dataType="string" [allowEditing]="true">
      <dxo-lookup [dataSource]="serviceTypes"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="MCO" dataField="getMco" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Broker" dataField="_trip._broker" [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Trip ID"
      dataField="_trip._tripId"
      cellTemplate="tripId_cell"
      [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Miles"
      dataField="_trip._miles"
      cellTemplate="tripMiles_cell"
      [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Trips per Day"
      dataField="_trips"
      cellTemplate="tripIds_cell"
      [allowEditing]="false"></dxi-column>

    <dxi-column caption="Trips per Day Count" dataField="_trips.length" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Claim Status" dataField="getClaimStatus" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Origin" dataField="getOrigin" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Destination" dataField="getDestination" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Cancelled" dataField="_cancelled" dataType="boolean" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Status" dataField="status" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Per Client Count" dataField="_consCount" dataType="number" [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="ARR/PU/DO"
      dataField="getTimes"
      cellTemplate="arrPuDoTimes_cell"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Meals Units"
      dataField="vTotalMealsUnits"
      dataType="number"
      [allowEditing]="false"></dxi-column>

    <dxi-column caption="Units" dataField="vUnits" dataType="number" [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Signature"
      dataField="imgFileId"
      dataType="string"
      cellTemplate="signature_cell"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Meal Photo"
      dataField="meta.mealPhoto"
      dataType="string"
      cellTemplate="mealPhoto_cell"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowEditing]="false"></dxi-column>

    <dxi-column caption="Note" dataField="getNote" dataType="string" [allowEditing]="false"></dxi-column>

    <!-- endregion Columns -->

    <!-- region Templates -->

    <ng-container *dxTemplate="let cellInfo of 'tripMiles_cell'">
      {{
        (cellInfo.data?._trip || cellInfo.data?._possibleTrip || {})['Trip Mileage'] ||
          (cellInfo.data?._trip || cellInfo.data?._possibleTrip || {})['miles']
      }}
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'tripId_cell'">
      <dx-select-box
        [id]="'trip_id_' + cellInfo.key"
        [dataSource]="tripDsoMap.get(cellInfo.key)"
        valueExpr="_tripId"
        displayExpr="_tripId"
        [placeholder]="
          cellInfo.data?._trips?.length === 0
            ? cellInfo.data?.vTripId
              ? cellInfo.data?.vTripId
              : 'No Trips'
            : 'Select...'
        "
        [value]="
          cellInfo.data?._trip?._tripId || (cellInfo.data?._valid ? cellInfo.data?._possibleTrip?._tripId : undefined)
        "
        [readOnly]="cellInfo.data?.meta?.locked ||
          cellInfo.data?._trips?.length < 1 ||
          (cellInfo.data?._trip?._broker === 'MTM' && cellInfo.data?._claim && cellInfo.data?._claim?.STATUS !== 'Denied') ||
          (cellInfo.data?._trip?._broker === 'CTS' && cellInfo.data?._claim && cellInfo.data?._claim?.STATUS === 'Paid')
        "
        [showClearButton]="true"
        (selectedItemChange)="tripId_selectedItemChange(cellInfo, $event)"
        (mouseenter)="tripIdTooltipVisibleMap[cellInfo.key] = true"
        (mouseleave)="tripIdTooltipVisibleMap[cellInfo.key] = false"
      ></dx-select-box>

      <dx-tooltip
        [target]="'#trip_id_' + cellInfo.key"
        position="bottom"
        [(visible)]="tripIdTooltipVisibleMap[cellInfo.key]"
        [closeOnOutsideClick]="false">
        <div
          *dxTemplate="let data = data; of: 'content'"
          [innerHTML]="
            cellInfo.data?._trip?._tripId
              | tripPopup: cellInfo.data?._trip?._broker:cellInfo.data?._trip?._date
              | async
              | sanitizeHtml
          "></div>
      </dx-tooltip>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'tripIds_cell'">
      <ng-container *ngFor="let trip of cellInfo.data?._trips">
        <span
          class="badge"
          [id]="'_' + cellInfo.key + '_trip_id_' + trip._tripId"
          (mouseenter)="tripTooltipVisibleMap[cellInfo.key + '-' + trip._tripId] = true"
          (mouseleave)="tripTooltipVisibleMap[cellInfo.key + '-' + trip._tripId] = false"
          >{{ trip._tripId }}</span
        >

        <dx-tooltip
          style="position: absolute"
          [target]="'#_' + cellInfo.key + '_trip_id_' + trip._tripId"
          position="bottom"
          [(visible)]="tripTooltipVisibleMap[cellInfo.key + '-' + trip._tripId]"
          [closeOnOutsideClick]="false">
          <div
            *dxTemplate="let data = data; of: 'content'"
            [innerHTML]="trip?._tripId | tripPopup: trip?._broker:trip?._date | async | sanitizeHtml"></div>
        </dx-tooltip>
      </ng-container>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'arrPuDoTimes_cell'">
      <small
        [id]="cellInfo.column.name + '-cell-' + cellInfo.data.id"
        (mouseenter)="toggleTooltip(cellInfo, true)"
        (mouseleave)="toggleTooltip(cellInfo, false)">
        <!--        <div *ngIf="cellInfo.data.scheduledTime">sch: {{cellInfo.data.scheduledTime}}</div>-->
        <div *ngIf="cellInfo.data.arrivedTime" [ngClass]="{ 'PuDo-warning': cellInfo.data._issueFields.arrivedTime }">
          arr: {{ cellInfo.data.arrivedTime }}
        </div>

        <div *ngIf="cellInfo.data.pickupTime" [ngClass]="{ 'PuDo-warning': cellInfo.data._issueFields.pickupTime }">
          pu: {{ cellInfo.data.pickupTime }}
        </div>

        <div *ngIf="cellInfo.data.dropoffTime" [ngClass]="{ 'PuDo-warning': cellInfo.data._issueFields.dropoffTime }">
          do: {{ cellInfo.data.dropoffTime }}
        </div>
      </small>

      <dx-tooltip
        #tooltip
        [id]="cellInfo.column.name + '-tooltip-' + cellInfo.data.id"
        [target]="'#' + cellInfo.column.name + '-cell-' + cellInfo.data.id"
        [(visible)]="toolTipVisible[cellInfo.column.name][cellInfo.data.id]">
        <div *dxTemplate="let data = data; of: 'content'">
          <span *ngIf="toolTipVisible[cellInfo.column.name][cellInfo.data.id]">
            <span [innerHTML]="getPrevTimes(cellInfo, tooltip) | async | sanitizeHtml"></span>
          </span>
        </div>
      </dx-tooltip>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'signature_cell'">
      <img
        style="max-height: 20px"
        [src]="cellInfo.value | signatureLink: 'FILE':'resize--20/trim' | async | sanitizeUrl" />
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'mealPhoto_cell'">
      <img
        [id]="'mealImg_' + cellInfo.data.id"
        style="max-height: 20px"
        (mouseenter)="popover[cellInfo.data.id] = true"
        (mouseleave)="popover[cellInfo.data.id] = false"
        [src]="cellInfo.value | pictureLink: 'resize-20-/trim' | async | sanitizeUrl" />

      <dx-popover [target]="'#mealImg_' + cellInfo.data.id" [visible]="popover[cellInfo.data.id]" position="left">
        <div *dxTemplate="let data = model; of: 'content'">
          <img [src]="cellInfo.value | pictureLink: 'resize--600' | async | sanitizeUrl" />
        </div>
      </dx-popover>
    </ng-container>

    <div *dxTemplate="let info of 'detail'">
      <dx-tab-panel>
        <dxi-item title="Details">
          <app-consumer-master-details [modelId]="info.data.consumerId"></app-consumer-master-details>
        </dxi-item>

        <dxi-item title="Driver">
          <ng-container
            *ngTemplateOutlet="
              driverInfo;
              context: { $implicit: driverData(info.data.employeeId) | async }
            "></ng-container>
        </dxi-item>

        <dxi-item title="Vehicle">
          <ng-container
            *ngTemplateOutlet="
              vehicleInfo;
              context: { $implicit: vehicleData(info.data.vehicleId) | async }
            "></ng-container>
        </dxi-item>

        <!--        <dxi-item title="Trip">-->
        <!--          <pre>{{info.data._trip | filterJson | json}}</pre>-->
        <!--        </dxi-item>-->
      </dx-tab-panel>
    </div>

    <!-- endregion Templates -->
  </dx-data-grid>
</app-content-w-header>

<!-- Templates -->

<ng-template #personEligibility let-model>
  <dx-data-grid
    width="100%"
    height="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [showBorders]="true"
    [dataSource]="model"
    [twoWayBindingEnabled]="false">
    <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="69894271-f5fb-4e42-8a7c-584d2b5475e8"></dxo-state-storing>

    <!--            <dxo-sorting mode="none"></dxo-sorting>-->

    <!-- region Columns -->

    <dxi-column caption="Month" dataField="_month" dataType="string"></dxi-column>

    <dxi-column caption="Month Name" dataField="_monthName" dataType="string"></dxi-column>

    <dxi-column caption="MCO" dataField="mco" dataType="string"></dxi-column>

    <dxi-column caption="Broker" dataField="_broker" dataType="string"></dxi-column>

    <dxi-column caption="Status" dataField="status" dataType="string"></dxi-column>

    <dxi-column caption="Error" dataField="error" dataType="string"></dxi-column>

    <!-- endregion -->

    <!-- region Templates -->
    <!-- endregion -->
  </dx-data-grid>
</ng-template>

<ng-template #vehicleInfo let-model>
  <pre>{{ model | json }}</pre>
</ng-template>

<ng-template #driverInfo let-model>
  <pre>{{ model | json }}</pre>
</ng-template>
