import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDateBoxComponent, DxPivotGridComponent } from 'devextreme-angular';
import DevExpress from 'devextreme';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import { map } from 'jquery';
import moment from 'moment';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { discretizeDates, discretizeMonths } from 'src/app/shared/classes/utils/time.utils';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { FullNamePipe } from 'src/app/shared/modules/ui/pipes/full-name.pipe';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { LoggerService, MyUser, MyUserApi } from 'src/app/shared/sdk';
import ArrayStoreOptions = DevExpress.data.ArrayStoreOptions;
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import PivotGridDataSourceField = DevExpress.data.PivotGridDataSourceField;
import PivotGridDataSourceOptions = DevExpress.data.PivotGridDataSourceOptions;

@Component({
  selector: 'app-gmap-log',
  templateUrl: './gmap-log.component.html',
  styleUrls: ['./gmap-log.component.scss'],
})
export class GMapLogComponent extends ABaseComponent implements OnInit {
  pivotGridDataSource: any;

  selectedFromValue?: Date = moment().subtract(1, 'week').toDate();
  selectedToValue?: Date = moment().toDate();

  @ViewChild(DxPivotGridComponent, { static: true }) grid: DxPivotGridComponent;
  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  dso: any = {
    store: [],
    fields: this.pivotFields,
  } as DataSourceOptions;
  grid_stateStoring: any;

  constructor(
    public logger: LoggerService,
    private dss: DataSourceService,
    private ui: UiService,
    public config: ConfigService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '49fb7dcf-9d9e-41a1-b9a7-6bd73a634757',
    };
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  ngOnInit(): void {
    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(() => {
          this.ui.showLoading();
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of(new ArrayStore({ data: [] }));
            }),
          ),
        ),
        tap(as => {
          this.dso = {
            store: as,
            fields: this.pivotFields,
          } as PivotGridDataSourceOptions;
          // this.grid.instance.refresh();
        }),
        tap(() => {
          this.ui.hideLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    return combineLatest([this.buildUsersMap()]).pipe(switchMap(async ([usersMap]) => await this.getData(usersMap)));
  }

  async getData(usersMap: any) {
    const self = this;
    const col = 'GMapLog';
    const start = moment(self.selectedFromValue).startOf('day').toISOString(true);
    const end = moment(self.selectedToValue).endOf('day').toISOString(true);
    const $and = [
      { $gte: ['$callTimestamp', { $dateFromString: { dateString: start } }] },
      { $lte: ['$callTimestamp', { $dateFromString: { dateString: end } }] },
    ];
    const aggregate = [{ $match: { $expr: { $and } } }, { $sort: { timestamp: -1 } }];
    return await gqlMongoLoad(self.dss, col, {}, aggregate)
      .toPromise()
      .then(data =>
        data.map(row => {
          const dt = row.callTimestamp ? moment(row.callTimestamp).format('MM/DD/YYYY_HH:mm:ss') : row.callId;
          const date = moment(row.forDate).format('MM/DD/YYYY');
          const caller = row.caller || '';
          const username = row.userId == -1 ? 'AutoDispatch' : usersMap[row.userId];
          const groupId = `${dt}_${caller}_${username}_for_${date}`;
          return { ...row, username, groupId };
        }),
      );
  }

  private async buildUsersMap() {
    const users: MyUser[] = await this.dss
      .getApi<MyUserApi>(MyUser)
      .find<MyUser>({
        where: {},
        include: [{ employee: ['person'] }],
      })
      .toPromise();

    return users.reduce(
      (p, u) => ({ ...p, [u.id]: u.username || new FullNamePipe(this.config).transform(u.employee) }),
      {},
    );
  }

  get exportFileName(): string {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to);

    return [
      moment().format('YYYY_MM_DD'),
      'Log_For',
      [fromMoment.format('YYYY_MM_DD'), toMoment.format('YYYY_MM_DD')].join('-'),
    ].join('_');
  }

  get pivotFields(): Array<PivotGridDataSourceField> {
    return [
      {
        caption: 'Group',
        dataField: 'groupId',
        area: 'row',
        width: 300,
        sortOrder: 'desc',
      },
      {
        caption: 'Time of Execution',
        dataField: 'timestamp',
        dataType: 'date',
        area: 'column',
      },
      {
        caption: 'Mode',
        dataField: 'mode',
        area: 'column',
      },
      {
        caption: 'API Call From',
        dataField: 'caller',
        area: 'column',
      },
      // {
      //   caption: 'API Call',
      //   dataField: 'params',
      //   area: 'column',
      // },
      // {
      //   caption: 'API Result',
      //   dataField: 'result',
      //   area: 'column',
      // },
      {
        caption: 'Duration of Execution (ms)',
        dataField: 'duration',
        area: 'data',
        summaryType: 'sum',
      },
      {
        caption: 'Price',
        dataField: 'price',
        area: 'data',
        summaryType: 'sum',
      },
      {
        caption: 'Calls',
        dataField: 'caller',
        area: 'data',
        summaryType: 'count',
      },
    ];
  }
}

