import * as tslib_1 from "tslib";
import { ChangeDetectorRef } from '@angular/core';
import moment from 'moment';
import { MyUtils, MyUtilsApi, Person } from 'src/app/shared/sdk';
import { API_ROUTER_URL } from 'src/app/config';
import { TIMEZONE } from '../../trips-audit/components/trips-audit-grid/trips-audit-grid.component';
import Guid from 'devextreme/core/guid';
export class RouterHelperService {
    constructor() {
        this.routesMap = {};
        this.dragCache = {};
        this.markerBaseUrl = '/assets/images/';
        this.betweenKeepStretcher = (trips, tripPos) => {
            const prev = trips[tripPos - 1];
            let cur = trips[tripPos];
            return cur && prev && cur.__consumer.keepStretcher && prev.__consumer.id === cur.__consumer.id;
        };
        this.findTripsPosition = (trips, dragTrips) => {
            let tripPos = trips.findIndex(t => moment(t.t, 'HH:mm').isAfter(moment(dragTrips[0].t, 'HH:mm')));
            if (tripPos === -1)
                tripPos = trips.length;
            this.betweenKeepStretcher(trips, tripPos) && tripPos++;
            return tripPos;
        };
        this.dragStart = (e, dataSource) => {
            const { fromData: fromDSIndex, fromIndex } = e;
            const { proposedGroup: fromGroup } = dataSource[fromDSIndex];
            const trip = fromGroup.trips[fromIndex];
            if (trip.x) {
                e.cancel = trip.x;
                return;
            }
            trip.fromIndex = fromIndex;
            const dragTrips = [trip];
            if (trip.__consumer.keepStretcher) {
                const prev = fromGroup.trips[fromIndex - 1];
                if (prev && prev.__consumer.id === trip.__consumer.id) {
                    prev.fromIndex = fromIndex - 1;
                    dragTrips.unshift(prev);
                }
                const next = fromGroup.trips[fromIndex + 1];
                if (next && next.__consumer.id === trip.__consumer.id) {
                    next.fromIndex = fromIndex + 1;
                    dragTrips.push(next);
                }
            }
            e.itemData = { dragTrips: JSON.parse(JSON.stringify(dragTrips)) };
        };
        this.dragMove = (e, cd) => {
            const { fromData: fromDSIndex, toData: toDSIndex, itemData } = e;
            if (fromDSIndex !== toDSIndex)
                return;
            itemData.dragTrips.forEach(t => {
                t.toIndex = undefined;
                t.nextFreeMinutes = undefined;
            });
            itemData.prevTrip = undefined;
            if (this.dragCache['draggingKey']) {
                this.dragCache['draggingKey'] = '';
                cd.detectChanges();
            }
        };
        this.makeDragCacheKey = (fromDSIndex, fromIndex, toDSIndex) => `${fromDSIndex}-${fromIndex}-${toDSIndex}`;
        this.dragChange = (e, http, cd, dataSource, dt) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { fromData: fromDSIndex, toData: toDSIndex, fromIndex, itemData } = e;
            const { proposedGroup: toGroup } = dataSource[toDSIndex];
            if (toGroup.x) {
                e.cancel = true;
                return;
            }
            const key = this.makeDragCacheKey(fromDSIndex, fromIndex, toDSIndex);
            if (this.dragCache['draggingKey'] === key)
                return;
            this.dragCache['draggingKey'] = key;
            // prevent multiple calls
            if (this.dragCache[key] === undefined) {
                this.dragCache[key] = null;
                itemData.dragTrips.forEach(t => ((t.nextFreeMinutes = 0), (t.dragPushed = true)));
                toGroup.trips.forEach(t => {
                    t.dragPushedBefore = t.dragPushedBefore || t.dragPushed;
                    t.dragPushed = false;
                });
                this.dragCache[key] = yield this.pushTripsToVehicle(http, itemData.dragTrips, toGroup, dt);
            }
            if (this.dragCache['draggingKey'] === key && this.dragCache[key]) {
                const trips = this.dragCache[key].trips;
                const tripPos = trips.findIndex(t => t.id === itemData.dragTrips[0].id);
                itemData.dragTrips.forEach((t, i) => {
                    t.nextFreeMinutes = trips[tripPos + i].nextFreeMinutes;
                    t.toIndex = tripPos + i;
                });
                itemData.prevTrip = trips[tripPos - 1];
                cd.detectChanges();
            }
            // let tripPos = this.findTripsPosition(toGroup.trips, dragTrips);
            // const _dur = (prev, next) =>
            //   (prev && next && moment.duration(moment(next.t, 'HH:mm').diff(moment(prev.dot, 'HH:mm'))).asMinutes()) || _GAP;
            // const parent = e.event.target && e.event.target.parentElement && e.event.target.parentElement.parentElement;
            // if (!parent) return;
            // const prev = toGroup.trips[tripPos - 1];
            // let cur = toGroup.trips[tripPos];
            // const prevGap = _dur(prev, dragTrips[0]);
            // const nextGap = _dur(dragTrips[dragTrips.length - 1], cur);
            // if (prevGap >= _GAP && nextGap >= _GAP) parent.classList.add('accepted');
            // else parent.classList.add('denied');
            // dragTrips.forEach((t, i) => (t.toIndex = tripPos + i));
        });
        this.dragAdd = (e, http, dataSource, dt) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { fromData: fromDSIndex, toData: toDSIndex, itemData: { dragTrips }, } = e;
            const fromDSItem = dataSource[fromDSIndex];
            const toDSItem = dataSource[toDSIndex];
            // clone group
            const fromGroup = JSON.parse(JSON.stringify(fromDSItem.proposedGroup));
            const toGroup = JSON.parse(JSON.stringify(toDSItem.proposedGroup));
            const fromIndex = dragTrips[0].fromIndex;
            const fromPrev = fromDSItem.proposedGroup.trips[fromIndex - 1];
            if (fromPrev)
                fromPrev.nextFreeMinutes = 0;
            fromDSItem.proposedGroup.trips.splice(fromIndex, dragTrips.length);
            let tripPos = this.findTripsPosition(toGroup.trips, dragTrips);
            const toPrev = toDSItem.proposedGroup.trips[tripPos - 1];
            if (toPrev)
                toPrev.nextFreeMinutes = 0;
            toDSItem.proposedGroup.trips.splice(tripPos, 0, ...dragTrips);
            e.fromComponent.refresh();
            e.toComponent.refresh();
            const fromProposedGroup = yield this.removeTripsFromVehicle(http, fromIndex, dragTrips.length, fromGroup, dt);
            const key = this.makeDragCacheKey(fromDSIndex, fromIndex, toDSIndex);
            let toProposedGroup = this.dragCache[key];
            this.dragCache = {};
            toProposedGroup = toProposedGroup || (yield this.pushTripsToVehicle(http, dragTrips, toGroup, dt));
            const fromDSItemActual = dataSource[fromDSIndex];
            const toDSItemActual = dataSource[toDSIndex];
            // ensure that the proposed group is the same
            if (fromDSItemActual.proposedGroup.trips.length === fromProposedGroup.trips.length) {
                fromDSItemActual.proposedGroup = fromProposedGroup;
                if (!(fromDSItemActual.manifestGroup.trips || []).length &&
                    !(fromDSItemActual.proposedGroup.trips || []).length &&
                    dataSource.filter(ds => !ds.manifestGroup.vehicle && !ds.manifestGroup.x).length > 1) {
                    dataSource.splice(fromDSIndex, 1);
                }
            }
            if (toDSItemActual.proposedGroup.trips.length === toProposedGroup.trips.length)
                toDSItemActual.proposedGroup = toProposedGroup;
        });
    }
    makeTripsPool(trips, manifestGroups, keepManifestTrips, selectedVehicleIdsSet) {
        let tripsPool = trips.filter(t => t.c && !t.x);
        const canceledTrips = trips.filter(t => t.x);
        let vehicles = [];
        if (keepManifestTrips) {
            tripsPool = manifestGroups
                .filter(group => !group.x && (!group.vehicle || !selectedVehicleIdsSet.has(group.vehicle.id)))
                .map(group => group.trips)
                .flat();
            vehicles = manifestGroups.filter(group => {
                group.preferred = selectedVehicleIdsSet.has(group.vehicle && group.vehicle.id);
                return group.preferred;
            });
        }
        else {
            tripsPool = tripsPool.filter(t => !t.lock);
            vehicles = JSON.parse(JSON.stringify(manifestGroups)).filter(group => {
                if (!group.vehicle)
                    return false;
                group.trips = group.trips.filter(t => t.lock);
                group.preferred = group.trips.length > 0;
                return true;
            });
        }
        return [tripsPool, vehicles, canceledTrips];
    }
    proposeGroups(http, tripsPool, vehiclesCount, withPreferred, withAvoid, useMatrix, vehicles, date, dss) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dragCache = {};
            this.validateTripsPool(tripsPool);
            const body = { tripsPool, vehiclesCount, withPreferred, withAvoid, vehicles, date };
            // callId = 16 random characters
            const callId = new Guid().toString();
            const url = `${API_ROUTER_URL}/propose?callId=${callId}&useMatrix=${useMatrix}`;
            return yield http.post(url, body).toPromise();
            // return await dss
            //   .getApi<RouterApi>(Router)
            //   .propose({ tripsPool, vehiclesCount, distribute, withPreferred, withAvoid, vehicles, date })
            //   .toPromise();
        });
    }
    logGmapOverwriteAllManifest(http, forDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const params = {
                forDate,
                caller: 'Router_Overwrite_Manifest',
                callId: new Guid().toString(),
                description: 'Overwrite all manifest',
                mode: 'Manual',
            };
            const url = `${API_ROUTER_URL}/logGmap?` + new URLSearchParams(params).toString();
            return yield http.get(url).toPromise();
        });
    }
    pushTripsToVehicle(http, trips, vehicle, date) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const body = { trips, vehicle, date };
            const callId = new Guid().toString();
            const url = `${API_ROUTER_URL}/pushTripsToVehicle?callId=${callId}`;
            const v = yield http.post(url, body).toPromise();
            v.load = this.calculateLoadPerGroup(v);
            return v;
        });
    }
    removeTripsFromVehicle(http, tripPos, count, vehicle, date) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const body = { tripPos, count, vehicle, date };
            const callId = new Guid().toString();
            const url = `${API_ROUTER_URL}/removeTripsFromVehicle?callId=${callId}`;
            const v = yield http.post(url, body).toPromise();
            v.load = this.calculateLoadPerGroup(v);
            return v;
        });
    }
    validateTripsPool(tripsPool) {
        if (!tripsPool.every(t => t.dot && t.dst && t.dur))
            throw new Error('Trips pool is invalid');
    }
    assignProposedGroups(dataSrc, proposedGroups, selectedVehicleIdsSet, selectedVehicleIds, manifestVehiclesMap, vehiclesMap, canceledTrips) {
        dataSrc = dataSrc.map(({ manifestGroup }, i) => {
            let proposedGroup = null;
            let idx = -1;
            if (!manifestGroup.vehicle && !manifestGroup.x)
                idx = proposedGroups.findIndex(group => !group.vehicle);
            if (selectedVehicleIdsSet.has(manifestGroup.vehicle && manifestGroup.vehicle.id)) {
                if (idx == -1) {
                    idx = proposedGroups.findIndex(group => (group.vehicle && group.vehicle.id === manifestGroup.vehicle.id && group.preferred) ||
                        (!group.vehicle && !manifestGroup.vehicle));
                }
                if (idx == -1) {
                    const { employee, escort } = manifestGroup;
                    const ids = [employee && employee.id, escort && escort.id].filter(id => id);
                    idx = proposedGroups.findIndex(group => (!group.vehicle || !group.preferred) && !group.avoidEmployees.includes(ids));
                }
                if (idx == -1)
                    idx = proposedGroups.findIndex(group => !group.vehicle || !group.preferred);
            }
            if (idx !== -1) {
                proposedGroup = proposedGroups.splice(idx, 1)[0];
                // const grCanceledTrips = canceledTrips.filter(t => manifestGroup.vehicle && t.v === manifestGroup.vehicle.id);
                // proposedGroup.trips = [...grCanceledTrips, ...proposedGroup.trips];
                // canceledTrips = canceledTrips.filter(t => !grCanceledTrips.find(t1 => t.id === t1.id));
                proposedGroup = this.prepareProposedTrips(manifestGroup, proposedGroup);
            }
            return { manifestGroup, proposedGroup };
        });
        const newVehicleIds = selectedVehicleIds.filter(id => !manifestVehiclesMap[id]);
        proposedGroups.forEach((pt, i) => {
            const vehicle = vehiclesMap[newVehicleIds[i]];
            // if (!vehicle && canceledTrips.length) {
            //   pt.trips = [...canceledTrips, ...pt.trips];
            //   canceledTrips = [];
            // }
            dataSrc.push({
                manifestGroup: { vehicle },
                proposedGroup: this.prepareProposedTrips({ vehicle }, pt),
            });
        });
        if (canceledTrips.length) {
            const proposedGroup = this.prepareProposedTrips({ vehicle: null, x: true }, { trips: canceledTrips });
            const idx = dataSrc.findIndex(({ manifestGroup }) => manifestGroup && manifestGroup.x);
            if (idx !== -1)
                dataSrc[idx].proposedGroup = proposedGroup;
            else
                dataSrc.push({ manifestGroup: { vehicle: null }, proposedGroup });
        }
        return dataSrc;
    }
    prepareProposedTrips(group, proposedGroup) {
        const { vehicle, employee, escort } = group;
        const v = Object.assign({}, group, { trips: null }, proposedGroup, { vehicle, employee, escort });
        v.load = this.calculateLoadPerGroup(v);
        return v;
    }
    makeManifestGroups(detailedTrips) {
        const [vehiclesMap] = detailedTrips.reduce(([p, empl], trip) => {
            let v = trip.v || -1;
            if (trip.x) {
                v = -2;
                trip.__vehicle = null;
                trip.__employee = null;
                trip.__escort = null;
            }
            trip.lock = false;
            if (!p[v]) {
                const employee = trip.__employee && !empl[trip.__employee.id] ? trip.__employee : null;
                if (employee)
                    empl[employee.id] = true;
                const escort = trip.__escort && !empl[trip.__escort.id] ? trip.__escort : null;
                if (escort)
                    empl[escort.id] = true;
                p[v] = { employee, escort, vehicle: trip.__vehicle, trips: [], x: trip.x };
            }
            if (trip.c)
                p[v].trips.push(trip);
            return [p, empl];
        }, [{}, {}]);
        const groups = Object.values(vehiclesMap).map((v) => {
            (v.trips || []).sort(({ t: ta }, { t: tb }) => (ta < tb ? -1 : ta > tb ? 1 : 0));
            v.load = this.calculateLoadPerGroup(v);
            return v;
        });
        return groups;
    }
    calculateLoadPerGroup(v) {
        const trips = v.trips;
        if (!trips || !trips.length)
            return null;
        const changeTripTime = 30;
        const tripsCount = trips.length;
        let [loadedMinutes, loadedDistance] = trips.reduce((p, { dur, dst }) => [p[0] + dur, p[1] + dst], [0, 0]);
        loadedMinutes += changeTripTime * (tripsCount - 1);
        const [travelMinutes, travelDistance] = [null, null, null, null];
        return { tripsCount, loadedMinutes, travelMinutes, travelDistance, loadedDistance };
    }
    sortDataSource(dataSource, order, employeeWorkingTimeMap) {
        return dataSource.sort(({ manifestGroup: a }, { manifestGroup: b }) => {
            if (a && a.x && (!b || !b.x))
                return -1;
            if ((!a || !a.x) && b && b.x)
                return 1;
            if (!a || !a.employee || !a.vehicle)
                return -1;
            if (!b || !b.employee || !b.vehicle)
                return 1;
            const [aTime, bTime] = [employeeWorkingTimeMap[a.employee.id], employeeWorkingTimeMap[b.employee.id]];
            let [aMin, bMin] = [
                (aTime && aTime.totalMinutesBeforeCurrent) || 0,
                (bTime && bTime.totalMinutesBeforeCurrent) || 0,
            ];
            if (order === 'least')
                return aMin - bMin;
            return bMin - aMin;
        });
    }
    getAddr(d, c, destinationsMap) {
        if (d === 'RESIDENCE') {
            const addr = c.person.contact.addresses.filter(a => a.meta.formatted)[0];
            return (addr && addr.meta.formatted) || '';
        }
        return (destinationsMap[d] && destinationsMap[d].address) || d;
    }
    getFromDoToPuAddresses(prev, next, destinationsMap) {
        const from = this.getAddr(prev.d, prev.__consumer, destinationsMap);
        const to = this.getAddr(next.o, next.__consumer, destinationsMap);
        return { from, to };
    }
    getFromDoToPuAddressesAndTime(date, prev, next, destinationsMap) {
        const time = moment.tz(`${date} ${next.t}`, 'YYYY-MM-DD HH:mm', TIMEZONE).utc().unix();
        return Object.assign({ time }, this.getFromDoToPuAddresses(prev, next, destinationsMap));
    }
    getRouteKey(dropOffAddress, pickupAddress, time) {
        return `${time}: ${dropOffAddress} -> ${pickupAddress}`;
    }
    addEmptyKeysToRoutesMap(date, group, destinationsMap) {
        if (!group || !group.trips)
            return;
        const trips = group.trips;
        for (let i = 0; i < trips.length - 1; i++) {
            const { from, to, time } = this.getFromDoToPuAddressesAndTime(date, trips[i], trips[i + 1], destinationsMap);
            const routeKey = this.getRouteKey(from, to, time);
            if (!this.routesMap[routeKey])
                this.routesMap[routeKey] = { from, to, time };
        }
    }
    fillRoutesMap(date, dataSource, destinationsMap, dss) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const data of dataSource) {
                this.addEmptyKeysToRoutesMap(date, data.manifestGroup, destinationsMap);
                this.addEmptyKeysToRoutesMap(date, data.proposedGroup, destinationsMap);
            }
            const routes = Object.values(this.routesMap).filter((route) => !route.duration);
            if (!routes.length)
                return;
            const routesResp = yield dss.getApi(MyUtils).computeRoutes(routes).toPromise();
            routes.forEach((route, i) => {
                const routeKey = this.getRouteKey(route.from, route.to, route.time);
                const { duration, distance } = routesResp[i] || { duration: { value: 0 }, distance: { value: 0 } };
                this.routesMap[routeKey] = Object.assign({}, route, { duration, distance });
            });
        });
    }
    calculateTravelPerGroup(date, group, destinationsMap, employeeWorkingTimeMap) {
        if (!group || !group.trips || !group.load)
            return;
        const trips = group.trips;
        let travelMinutes = group.load.loadedMinutes;
        let travelDistance = group.load.loadedDistance;
        for (let i = 0; i < trips.length - 1; i++) {
            const { from, to, time } = this.getFromDoToPuAddressesAndTime(date, trips[i], trips[i + 1], destinationsMap);
            const routeKey = this.getRouteKey(from, to, time);
            if (this.routesMap[routeKey]) {
                travelMinutes += Math.floor(this.routesMap[routeKey].duration.value / 60);
                travelDistance += this.routesMap[routeKey].distance.value;
            }
        }
        const firstTrip = trips[0];
        const lastTrip = trips[trips.length - 1];
        let workingMinutes = group.workingMinutes;
        if (!workingMinutes && group.employee && employeeWorkingTimeMap[group.employee.id])
            workingMinutes = employeeWorkingTimeMap[group.employee.id].currentMinutes;
        if (!workingMinutes)
            workingMinutes = moment.duration(moment(lastTrip.dot, 'hh:mm').diff(moment(firstTrip.t, 'hh:mm'))).asMinutes();
        let percent = Math.floor((travelMinutes * 100) / workingMinutes);
        if (percent > 100)
            percent = 100;
        const freeMinutes = workingMinutes - travelMinutes;
        group.load = Object.assign({}, group.load, { percent, travelMinutes, travelDistance, freeMinutes });
    }
    calculateTravel({ manifest, proposed }, date, dataSource, destinationsMap, dss, employeeWorkingTimeMap) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.fillRoutesMap(date, dataSource, destinationsMap, dss);
            for (const data of dataSource) {
                this.calculateTravelPerGroup(date, data.manifestGroup, destinationsMap, employeeWorkingTimeMap);
                this.calculateTravelPerGroup(date, data.proposedGroup, destinationsMap, employeeWorkingTimeMap);
            }
            this.calculateCombinedTravel(manifest, dataSource.map(d => d.manifestGroup));
            this.calculateCombinedTravel(proposed, dataSource.map(d => d.proposedGroup));
        });
    }
    calculateCombinedTravel(totals, groups) {
        const groupsWithVehicle = groups.filter(v => v && v.vehicle && v.load);
        const [totalMinutes, totalDistance] = groupsWithVehicle.reduce((p, { load: { travelMinutes, travelDistance } }) => [p[0] + travelMinutes, p[1] + travelDistance], [0, 0]);
        totals.combinedTravelMinutes = totalMinutes;
        totals.combinedTravelDistance = totalDistance;
        totals.combinedFreeMinutes =
            totalMinutes > totals.combinedLoadedMinutes ? totalMinutes - totals.combinedLoadedMinutes : 0;
    }
    calculateTotals(groups) {
        const groupsWithVehicle = groups.filter(v => v.vehicle);
        const totalVehicles = groupsWithVehicle.length;
        const [totalLoadedMin, totalFreeMin, totalDistance] = groups
            .filter(g => g.load)
            .reduce((p, { load: { loadedMinutes, freeMinutes, loadedDistance } }) => [
            p[0] + loadedMinutes,
            p[1] + freeMinutes,
            p[2] + loadedDistance,
        ], [0, 0, 0]);
        const totalNumberOfTrips = groups
            .filter(g => g.load)
            .reduce((p, { load: { tripsCount } }) => p + tripsCount, 0);
        return {
            totalVehicles,
            combinedLoadedMinutes: totalLoadedMin,
            totalNumberOfTrips,
            combinedLoadedDistance: totalDistance,
            combinedTravelMinutes: null,
            combinedTravelDistance: null,
        };
    }
    getRoutesAndMarkers(trips, destinationsMap) {
        const routes = [];
        const markers = [];
        for (let i = 0; i < trips.length; i++) {
            const trip = trips[i];
            const origing = this.getAddr(trip.o, trip.__consumer, destinationsMap);
            const destination = this.getAddr(trip.d, trip.__consumer, destinationsMap);
            routes.push(this.getRoute([origing, destination], false, i));
            const nextTrip = trips[i + 1];
            if (nextTrip) {
                const nextOriging = this.getAddr(nextTrip.o, nextTrip.__consumer, destinationsMap);
                routes.push(this.getRoute([destination, nextOriging], true));
            }
            markers.push(...this.getPuDoMarkers([
                { location: origing, dateTime: trip.t },
                { location: destination, dateTime: trip.dot },
            ], trip.__consumer.person));
        }
        return { routes, markers };
    }
    getPuDoMarkers(positions, ePerson) {
        return [
            Object.assign({}, positions[0], { iconSrc: this.markerBaseUrl + 'marker-pickup.png', isPU: true }),
            Object.assign({}, positions[1], { iconSrc: this.markerBaseUrl + `marker-dropoff.png` }),
        ].map(({ dateTime, location, iconSrc, isPU }) => ({
            iconSrc,
            location: `${location}`,
            tooltip: {
                text: `${ePerson.lastname}, ${ePerson.firstname}` +
                    `<br/><em>${isPU ? 'Pick Up' : 'Drop Off'} Time:</em> ${moment(dateTime, 'HH:mm:ss').format('hh:mm A')}`,
                isShown: false,
            },
        }));
    }
    getTripAddresses(trip, destinationsMap) {
        const from = this.getAddr(trip.o, trip.__consumer, destinationsMap);
        const to = this.getAddr(trip.d, trip.__consumer, destinationsMap);
        return { from, to };
    }
    getRoute(locations, isBetween, trupNumber = 0) {
        const colors = ['red', 'green', 'blue', 'brown', '#0083ff', '#3cbc4d', '#a02370', '#7f7213', '#12677c'];
        return {
            weight: 4,
            color: isBetween ? 'red' : colors[trupNumber % colors.length],
            opacity: isBetween ? 0.3 : 0.8,
            mode: '',
            locations: [...locations],
            tooltip: {
                text: 'asdf',
                isShown: true,
            },
        };
    }
}
