import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { UnassignedClientsComponent } from './components/unassigned-clients/unassigned-clients.component';
import { ServiceAuthorizationComponent } from './containers/service-authorization/service-authorization.component';
import { RenewAuthsComponent } from './components/renew-auths/renew-auths.component';

@NgModule({
  declarations: [UnassignedClientsComponent, ServiceAuthorizationComponent, RenewAuthsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,

    MyCommonModule,
    UiModule,

    NgxJsonViewerModule,
  ],
})
export class ServiceAuthorizationModule {}
