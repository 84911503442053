<app-content-w-header header="Validate Services V2">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset" style="">
      <div style="display: flex; align-items: center">
        <dx-date-box #from width="100px" [(value)]="selectedFromValue" (onValueChanged)='from_onValueChanged($event)'></dx-date-box>
        <span>&nbsp;&ndash;&nbsp;</span>
        <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
      </div>

      <dx-select-box
        [dataSource]="facilityDso$ | async"
        displayExpr="name"
        valueExpr="id"
        [(value)]="facilityId"
        placeholder="Facility"
        [showClearButton]="true"
        (onSelectionChanged)="facility_onSelectionChanged($event)"
      ></dx-select-box>

      <dx-select-box
        [dataSource]="['OPEN_DAY', 'CLOSE_DAY']"
        [(value)]="dayServiceMarker"
        [showClearButton]="true"
        placeholder="Trip Day Marker"
        width="120px"></dx-select-box>

      <dx-select-box
        [dataSource]="validationStates"
        displayExpr="t"
        valueExpr="v"
        [(value)]="validationState"
        [showClearButton]="true"
        placeholder="Validation Status"
        width="130px"></dx-select-box>

      <dx-select-box
        [dataSource]="[true, false]"
        [(value)]="hasMissingData"
        [showClearButton]="true"
        placeholder="Has Missing Data"
        width="140px"></dx-select-box>

      <dx-select-box
        [dataSource]="[true, false]"
        [(value)]="possibleIssues"
        [showClearButton]="true"
        placeholder="Possible Issues"
        width="130px"></dx-select-box>

      <!--      <dx-select-box-->
      <!--        [dataSource]="['KEYSTONE', 'AMERIHEALTH', 'PHW', 'UPMC', 'Other']"-->
      <!--        [(value)]="mco"-->
      <!--        [showClearButton]="true"-->
      <!--        placeholder="MCO"-->
      <!--        width="70px"-->
      <!--      ></dx-select-box>-->

      <!--      <dx-select-box-->
      <!--        [dataSource]="['CTS', 'MTM', 'LGTC']"-->
      <!--        [(value)]="broker"-->
      <!--        [showClearButton]="true"-->
      <!--        placeholder="Broker"-->
      <!--        width="80px"-->
      <!--      ></dx-select-box>-->

      <app-employee-drop-down-grid
        [(value)]="driverId"
        [filter]="[]"
        [dataSource]="employeeDso$ | async"
        [remoteOperations]="true"
        [grid_columns]="[
          {
            dataField: 'tenantId',
            caption: 'Tenant',
            lookup: {
              dataSource: facilityDso$ | async,
              valueExpr: 'id',
              displayExpr: 'shortname'
            }
          },
          { dataField: 'person_firstname', caption: 'Firstname' },
          { dataField: 'person_lastname', caption: 'Lastname' }
        ]"
        [showClearButton]="true"></app-employee-drop-down-grid>

      <app-consumer-drop-down-grid
        [(value)]="clientId"
        [filter]="[]"
        [dataSource]="consumerDso$ | async"
        [remoteOperations]="true"
        [grid_columns]="[
          { dataField: 'mci', caption: 'MCI' },
          {
            dataField: 'tenantId',
            caption: 'Tenant',
            lookup: {
              dataSource: facilityDso$ | async,
              valueExpr: 'id',
              displayExpr: 'shortname'
            }
          },
          { dataField: 'person_firstname', caption: 'Firstname' },
          { dataField: 'person_lastname', caption: 'Lastname' }
        ]"
        [showClearButton]="true"
      ></app-consumer-drop-down-grid>

      <dx-text-box width="100px" [(value)]="mci" placeholder="MCI"></dx-text-box>

      <dx-select-box
        [dataSource]="serviceTypes"
        [(value)]="serviceType"
        [showClearButton]="true"
        placeholder="Service Type"
        width="110px"></dx-select-box>

      <dx-select-box
        [dataSource]="directionMarkers"
        [(value)]="directionMarker"
        [showClearButton]="true"
        placeholder="Direction Marker"
        width="130px"></dx-select-box>

      <dx-select-box
        [dataSource]="[true, false]"
        [(value)]="hasTrip"
        [showClearButton]="true"
        placeholder="Has Auth"
        width="100px"></dx-select-box>

      <dx-check-box [(value)]="withDriverSigns" text="With Driver Signs"></dx-check-box>
      <dx-check-box [(value)]="withEscortSigns" text="With Escort Signs"></dx-check-box>

      <!--      <dx-select-box-->
      <!--        [dataSource]="[true, false]"-->
      <!--        [(value)]="hasClaim"-->
      <!--        [showClearButton]="true"-->
      <!--        placeholder="Has Claim"-->
      <!--        width="100px"-->
      <!--      ></dx-select-box>-->

      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter()"></dx-button>
      </div>
    </div>

    <!--  -->

    <dx-popover [target]="popoverTarget"
                [visible]="!!popoverTarget"
                position="left"
    >
      <ng-container *dxTemplate="let data = model; of: 'content'">

        <ng-container [ngSwitch]="popoverTarget?.split('_')[0]">

          <ng-container *ngSwitchCase="'#mealImg'">
            <div style="width: 500px; height: 500px; display: flex; justify-content: center; align-items: center;">
              <img style="max-width: 100%; max-height: 100%;"
                   [src]="popoverData?.meta?.mealPhoto | pictureLink: 'resize--600' | async | sanitizeUrl" />
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'#addrMismatch'">
            <div>
              <div>
                [service origin: <strong>{{popoverData?._addresses?.service?.from}}</strong>] ->
                [service dest: <strong>{{popoverData?._addresses?.service?.to}}</strong>]
              </div>

              <div>
                [trip origin: <strong>{{popoverData?._addresses?.trip?.from}}</strong>] ->
                [trip dest: <strong>{{popoverData?._addresses?.trip?.to}}</strong>]
              </div>
            </div>
          </ng-container>

        </ng-container>

      </ng-container>
    </dx-popover>

    <dx-tooltip #tooltip
      style="position: absolute"
      [target]="tooltipTarget"
      [visible]="!!tooltipTarget"
      [closeOnOutsideClick]="false"
      position="bottom"
    >
      <ng-container *dxTemplate="let data = model; of: 'content'">

        <ng-container [ngSwitch]="tooltipTarget?.split('_')[0]">
          <ng-container *ngSwitchCase="'#tripId'">
            <span
              [innerHTML]="tooltipData?._trip?._tripId | tripPopup:tooltipData?._trip?._broker:tooltipData?._trip?._date | async | sanitizeHtml"
            ></span>
          </ng-container>

          <ng-container *ngSwitchCase="'#tripListItm'">
            <div
              [innerHTML]="tooltipData?._tripId | tripPopup:tooltipData?._broker:tooltipData?._date | async | sanitizeHtml"
            ></div>
          </ng-container>

          <ng-container *ngSwitchCase="'#timesPuDo'">
            <span [innerHTML]="getPrevTimes(tooltipData, tooltip) | async | sanitizeHtml"></span>
          </ng-container>
        </ng-container>

      </ng-container>
    </dx-tooltip>

  </mat-card>

  <dx-data-grid
    class="content"
    height="100%"
    [dataSource]="dso"
    keyExpr="id"

    [stateStoring]="grid_stateStoring"
    [twoWayBindingEnabled]="true"
    [repaintChangesOnly]="true"

    [errorRowEnabled]="false"
    [searchPanel]="{ visible: false }"
    [headerFilter]="{ visible: true }"
    [filterRow]="{ visible: true }"
    [filterPanel]="{ visible: true }"
    [columnChooser]="{enabled: true, mode: 'select'}"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="true"

    (onInitialized)="grid_onInitialized($event)"
    (onDataErrorOccurred)="grid_onDataErrorOccurred($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
    (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
    (onCellPrepared)="grid_onCellPrepared($event)"

    (onCellClick)="grid_onCellClick($event)"
    (onSelectionChanged)="grid_onSelectionChanged($event)"

    (onSaving)="grid_onSaving($event)"
  >
    <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
    <dxo-pager [visible]="true" [showInfo]="true" [showPageSizeSelector]="true" [showNavigationButtons]="true"
               [allowedPageSizes]="[50, 100, 200]"
    ></dxo-pager>

    <dxo-grouping [contextMenuEnabled]="true"></dxo-grouping>
    <dxo-group-panel [visible]="true"></dxo-group-panel>

    <dxo-selection mode="multiple" selectAllMode="page" [deferred]="false"></dxo-selection>

    <dxo-editing
      [allowAdding]="false"
      [allowDeleting]="false"
      [allowUpdating]="true"
      [mode]="'cell'"
      [(changes)]="changes"
      [(editRowKey)]="editRowKey"
      refreshMode="repaint"
    ></dxo-editing>

    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

    <dxo-scrolling
      [useNative]="true"
    ></dxo-scrolling>
    <!--    mode="virtual"-->
    <!--    [preloadEnabled]="true"-->
    <!--    rowRenderingMode="virtual"-->
    <!--    columnRenderingMode="virtual"-->

    <!--    <dxo-export [enabled]="true" fileName="service-export"></dxo-export>-->

    <!-- region Columns -->

    <!--<dxi-column type="selection" [sortIndex]="0"></dxi-column>-->

    <dxi-column caption="Validation Status" dataField="validationState" dataType="string" [allowEditing]="true"
                [cssClass]="'allow-edit'">
      <dxo-lookup [dataSource]="validationStates" valueExpr="v" displayExpr="t"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Day Service Marker" dataField="_dayServiceMarker" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Facility" dataField="_tenant.name" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Date"
      dataField="vdate"
      dataType="date"
      format="shortDate"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      [allowEditing]="false"
    ></dxi-column>

    <dxi-column caption="MCI" dataField="consumer_mci" [allowEditing]="false"></dxi-column>
    <dxi-column caption="Client Last Name" dataField="consumer_person_lastname" [allowEditing]="false"></dxi-column>
    <dxi-column caption="Client First Name" dataField="consumer_person_firstname" [allowEditing]="false"></dxi-column>
    <dxi-column caption="Client" dataField="consumerId" [allowEditing]="false">
      <dxo-lookup [dataSource]="consumerSubDso$ | async" valueExpr="consumerId" displayExpr="name"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Employee Last Name" dataField="employee_person_lastname" [allowEditing]="false"></dxi-column>
    <dxi-column caption="Employee First Name" dataField="employee_person_firstname" [allowEditing]="false"></dxi-column>
    <dxi-column caption="Employee" dataField="employeeId" [allowEditing]="false">
      <dxo-lookup [dataSource]="employeeSubDso$ | async" valueExpr="employeeId" displayExpr="name"></dxo-lookup>
    </dxi-column>

    <!--    <dxi-column caption="Vehicle" dataField="vehicle_internalId" [allowEditing]="false"></dxi-column>-->
    <dxi-column caption="Vehicle" dataField="vehicleId" [allowEditing]="false">
      <dxo-lookup [dataSource]="vehicleSubDso$ | async" valueExpr="vehicleId"
                  displayExpr="vehicle_internalId"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Manifest Rec" dataField="manifestRecId" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Marker" dataField="marker" [allowEditing]="true" [cssClass]="'allow-edit'">
      <dxo-lookup [dataSource]="directionMarkers"></dxo-lookup>
    </dxi-column>

    <!--    -->

    <dxi-column caption="Service Type" dataField="vServiceType" dataType="string" [allowEditing]="false">
      <dxo-lookup [dataSource]="serviceTypes"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="MCO" dataField="_client.mco" [allowEditing]="false"></dxi-column>
    <dxi-column caption="Broker" dataField="_trip._broker" [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Trip ID"
      dataField="_trip._tripId"
      cellTemplate="tripId_cell"
      [allowEditing]="false"
    ></dxi-column>

    <dxi-column
      caption="Miles"
      dataField="_trip.__miles"
      [allowEditing]="false"
    ></dxi-column>

    <dxi-column
      caption="Trips per Day"
      dataField="_trips"
      cellTemplate="tripIds_cell"
      [allowEditing]="false"
    ></dxi-column>

    <dxi-column caption="Trips per Day Count" dataField="_trips.length" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Claim Status" dataField="_trip.__claim.__status" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Origin" dataField="_rec.o" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Destination" dataField="_rec.d" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Cancelled" dataField="_cancelled" dataType="boolean" [allowEditing]="false"
                [showEditorAlways]="false"></dxi-column>

    <dxi-column caption="Status" dataField="status" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Per Client Count" dataField="_consCount" dataType="number" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Meals Units" dataField="vTotalMealsUnits" dataType="number"
                [allowEditing]="false"></dxi-column>

    <dxi-column caption="Units" dataField="vUnits" dataType="number" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Certification">
      <dxi-column caption="Certification Name" dataField="meta.certificationOnSign.certificationName"
                  dataType="string" [allowEditing]="false"></dxi-column>
      <dxi-column caption="Certification Type" dataField="meta.certificationOnSign.certificationType"
                  dataType="string" [allowEditing]="false"></dxi-column>
      <dxi-column caption="Certification Reason" dataField="meta.certificationOnSign.certificationReason"
                  dataType="string" [allowEditing]="false"></dxi-column>
    </dxi-column>

    <dxi-column
      caption="Client Signature"
      dataField="imgFileId"
      cellTemplate="signature_cell"
      [allowEditing]="false"
      [allowSearch]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowSorting]="false"
      [allowGrouping]="false"
    ></dxi-column>

    <dxi-column
      caption="Driver Signature"
      dataField="_driverSign.imgFileId"
      cellTemplate="signature_cell"
      [allowSearch]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowSorting]="false"
      [allowGrouping]="false"
    ></dxi-column>

    <dxi-column
      caption="Attendant Signature"
      dataField="_escortSign.imgFileId"
      cellTemplate="signature_cell"
      [allowSearch]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowSorting]="false"
      [allowGrouping]="false"
    ></dxi-column>

    <dxi-column
      caption="Meal Photo"
      dataField="meta.mealPhoto"
      cellTemplate="mealPhoto_cell"
      [allowEditing]="false"
      [allowSearch]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowSorting]="false"
      [allowGrouping]="false"
    ></dxi-column>

<!--        <dxi-column-->
<!--          caption="Mismatch Location"-->
<!--          dataField="_addrMismatch"-->
<!--          cellTemplate="addrMismatch_cell"-->
<!--          [allowEditing]="false"-->
<!--          [allowSearch]="false"-->
<!--          [allowFiltering]="false"-->
<!--          [allowHeaderFiltering]="false"-->
<!--          [allowSorting]="false"-->
<!--          [allowGrouping]="false"-->
<!--        >-->
<!--          &lt;!&ndash;      <dxo-lookup [dataSource]="['dest', 'origin', 'both', 'swapped', 'valid']"></dxo-lookup>&ndash;&gt;-->
<!--        </dxi-column>-->

    <dxi-column
      caption="ARR/PU/DO"
      cellTemplate="arrPuDoTimes_cell"
      name="arrPuDoTimes"
      [allowEditing]="false"
      [allowSearch]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowSorting]="false"
      [allowGrouping]="false"
    ></dxi-column>

    <dxi-column
      caption="Note"
      dataField="getNote"
      dataType="string"
      [allowEditing]="false"
      [allowSearch]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowSorting]="false"
      [allowGrouping]="false"
    ></dxi-column>

    <!-- endregion Columns -->

    <!-- region Templates -->

    <ng-container *dxTemplate="let cellInfo of 'signature_cell'">
      <img
        style="max-height: 20px"
        [src]="cellInfo.value | signatureLink: 'FILE':'resize--20/trim' | async | sanitizeUrl" />
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'mealPhoto_cell'">
      <div
        [id]="'mealImg_' + cellInfo.data.id"
      ><img
        style="max-height: 20px;"
        [src]="cellInfo.value | pictureLink: 'resize-20-/trim' | async | sanitizeUrl"
        (mouseenter)="popoverTarget = '#mealImg_' + cellInfo.data.id; popoverData = cellInfo.data;"
        (mouseleave)="popoverTarget = null; popoverData = null"
      /></div>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'tripId_cell'">
      <div
        [id]="'tripId_' + cellInfo.key"
        (mouseenter)="tooltipTarget = '#tripId_' + cellInfo.key; tooltipData = cellInfo.data;"
        (mouseleave)="tooltipTarget = null; tooltipData = null"
      >
        <dx-select-box
          [dataSource]="tripDsoMap.get(cellInfo.key)"
          valueExpr="_tripId"
          displayExpr="_tripId"

          [placeholder]="cellInfo.data?._trips?.length === 0 ? 'No Trips' : 'Select...'"
          [value]="cellInfo.data?.vTripId"

          [readOnly]="cellInfo.data._tripIdLocked()"
          [showClearButton]="true"

          (selectedItemChange)="tripId_selectedItemChange(cellInfo, $event)"
          (onValueChanged)="tripId_onValueChanged(cellInfo, $event)"
        ></dx-select-box>

<!--        [placeholder]="cellInfo.data?._trips?.length === 0-->
<!--        ? cellInfo.data?.vTripId-->
<!--        ? cellInfo.data?.vTripId-->
<!--        : 'No Trips'-->
<!--        : 'Select...'"-->
<!--        [value]="cellInfo.data?._trip?._tripId"-->

      </div>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'tripIds_cell'">
      <ng-container *ngFor="let trip of cellInfo.data?._trips">
        <span
          class="badge"
          [id]="'tripListItm_' + cellInfo.key + '_' + trip._tripId"
          (mouseenter)="tooltipTarget = '#tripListItm_' + cellInfo.key + '_' + trip._tripId; tooltipData = trip"
          (mouseleave)="tooltipTarget = null; tooltipData = null"
        >{{ trip._tripId }}</span>
      </ng-container>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'arrPuDoTimes_cell'">
      <small
        [id]="'timesPuDo_' + cellInfo.data.id"
        (mouseenter)="tooltipTarget = '#timesPuDo_' + cellInfo.data.id; tooltipData = cellInfo.data;"
        (mouseleave)="tooltipTarget = null; tooltipData = null"
      >
        <!--        <div *ngIf="cellInfo.data?.scheduledTime">sch: {{cellInfo.data?.scheduledTime}}</div>-->

        <div *ngIf="cellInfo.data?.arrivedTime"
             [ngClass]="{ 'PuDo-warning': cellInfo.data?._issueFields?.arrivedTime }">
          arr: {{ cellInfo.data.arrivedTime }}
        </div>

        <div *ngIf="cellInfo.data?.pickupTime" [ngClass]="{ 'PuDo-warning': cellInfo.data?._issueFields?.pickupTime }">
          pu: {{ cellInfo.data.pickupTime }}
        </div>

        <div *ngIf="cellInfo.data?.dropoffTime"
             [ngClass]="{ 'PuDo-warning': cellInfo.data?._issueFields?.dropoffTime }">
          do: {{ cellInfo.data.dropoffTime }}
        </div>
      </small>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'addrMismatch_cell'">
      <div
        [id]="'addrMismatch_' + cellInfo.data.id"
        (mouseenter)="popoverTarget = '#addrMismatch_' + cellInfo.data.id; popoverData = cellInfo.data;"
        (mouseleave)="popoverTarget = null; popoverData = null"
      >{{cellInfo.value}}</div>
    </ng-container>

    <!--    -->

    <div *dxTemplate="let info of 'detail'">
      <dx-tab-panel>
<!--        <dxi-item title="Details">-->
<!--          <app-sign-details [modelId]="info.data.id"></app-sign-details>-->
<!--        </dxi-item>-->

        <dxi-item title="Client">
          <app-consumer-master-details [modelId]="info.data.consumerId"></app-consumer-master-details>
        </dxi-item>

        <dxi-item title="Driver">
          <ng-container
            *ngTemplateOutlet="
              driverInfo;
              context: { $implicit: driverData(info.data.employeeId) | async }
            "></ng-container>
        </dxi-item>

        <dxi-item title="Vehicle">
          <ng-container
            *ngTemplateOutlet="
              vehicleInfo;
              context: { $implicit: vehicleData(info.data.vehicleId) | async }
            "></ng-container>
        </dxi-item>

        <!--        <dxi-item title="Trip">-->
        <!--          <pre>{{info.data._trip | filterJson | json}}</pre>-->
        <!--        </dxi-item>-->
      </dx-tab-panel>
    </div>

    <!-- endregion Templates -->
  </dx-data-grid>
</app-content-w-header>

<!-- Templates -->

<ng-template #personEligibility let-model>
  <dx-data-grid
    width="100%"
    height="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [showBorders]="true"
    [dataSource]="model"
    [twoWayBindingEnabled]="false">
    <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="69894271-f5fb-4e42-8a7c-584d2b5475e8"></dxo-state-storing>

    <!--            <dxo-sorting mode="none"></dxo-sorting>-->

    <!-- region Columns -->

    <dxi-column caption="Month" dataField="_month" dataType="string"></dxi-column>

    <dxi-column caption="Month Name" dataField="_monthName" dataType="string"></dxi-column>

    <dxi-column caption="MCO" dataField="mco" dataType="string"></dxi-column>

    <dxi-column caption="Broker" dataField="_broker" dataType="string"></dxi-column>

    <dxi-column caption="Status" dataField="status" dataType="string"></dxi-column>

    <dxi-column caption="Error" dataField="error" dataType="string"></dxi-column>

    <!-- endregion -->

    <!-- region Templates -->
    <!-- endregion -->
  </dx-data-grid>
</ng-template>

<ng-template #vehicleInfo let-model>
  <ngx-json-viewer [json]="model || {}" [expanded]="false"></ngx-json-viewer>
</ng-template>

<ng-template #driverInfo let-model>
  <ngx-json-viewer [json]="model || {}" [expanded]="false"></ngx-json-viewer>
</ng-template>
