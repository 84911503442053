import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import identity from 'lodash-es/identity';
import orderBy from 'lodash-es/orderBy';
import moment from 'moment';
import { utc } from 'moment/moment';
import { BehaviorSubject, from, of } from 'rxjs';
import { catchError, filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { MongoLoadOptionsConverter } from '../../../../shared/classes/loopback-custom-store/generic/load-options-converters/MongoLoadOptionsConverter';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { AuthService, AuthServiceApi, Consumer, Facility, LoggerService } from '../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { DlgAuthEditComponent } from '../../dialogs/dlg-auth-edit/dlg-auth-edit.component';

@Component({
  selector: 'app-authorization-grid',
  templateUrl: './authorization-grid.component.html',
  styleUrls: ['./authorization-grid.component.scss'],
})
export class AuthorizationGridComponent extends ABaseComponent implements OnInit {
  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
    public consumerHelper: ConsumerHelperService,
    protected dialog: MatDialog,
  ) {
    super(logger);

    this.grid1_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '242a2754-75d3-4508-b54a-f72bbd0fd532',
    };

    this.grid2_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '643392a9-8a8f-4801-ab40-1ad191a5f7db',
    };

    this.grid1_detail_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '414fa55f-7d45-40e2-b146-694031b0cc1a',
    };

    this.grid2_detail_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'fe603afa-632b-4e20-b278-a6182413ecas',
    };
  }

  $filterEvent1$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  $filterEvent2$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  @ViewChild('grid1', { static: true }) grid1: DxDataGridComponent;
  @ViewChild('grid2', { static: true }) grid2: DxDataGridComponent;

  grid1_stateStoring: any;
  grid1_detail_stateStoring: any;

  grid2_stateStoring: any;
  grid2_detail_stateStoring: any;

  dso1: DataSourceOptions;
  dso2: DataSourceOptions;

  facilityDso: DataSourceOptions = [];
  consumerDso: DataSourceOptions = [];

  selectedDateValue1?: Date = new Date();
  importedBeforeDateValue1?: Date; // = new Date();

  selectedDateValue2?: Date = new Date();
  selectedCodes2: any[] = [];

  dso3 = {};

  codeDso: any;

  grid2FilterValue: any;

  ngOnInit() {
    super.ngOnInit();

    this.dso1 = this.buildDataSource1();
    this.dso2 = this.buildDataSource2();

    this.facilityDso = this.buildFacilityDataSource();
    this.consumerDso = this.buildConsumerDataSource();

    this.codeDso = this.buildCodeDso();

    this.$filterEvent1$
      .pipe(
        tap(() => {
          this.ui.showLoading();
        }),
        switchMap(async () => (this.dso1 = this.buildDataSource1())),
        tap(() => {
          this.ui.hideLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    this.$filterEvent2$
      .pipe(
        tap(() => {
          this.ui.showLoading();
        }),
        switchMap(async () => (this.dso2 = this.buildDataSource2())),
        tap(() => {
          this.ui.hideLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildCodeDso() {
    const self = this;

    const col = 'ExportsAuthsDataCache';
    const aggregate = [
      {
        $match: {
          _inactive: { $ne: true },
        },
      },
      {
        $group: { _id: '$Code' },
      },
      {
        $sort: { _id: 1 },
      },
    ];

    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
    });
    const dso: DataSourceOptions = {
      store,
      // sort: [{selector: '_date', desc: true}],
      // postProcess: (data: Array<any>): Array<any> => {
      //   return data;
      // },
    } as DataSourceOptions;
    return dso;
  }

  private buildDataSource1() {
    const self = this;

    const activeAuthMoment =
      this.selectedDateValue1 && moment(this.selectedDateValue1).isValid()
        ? utc(moment(this.selectedDateValue1).format('YYYY-MM-DD'))
        : null;

    const importedBeforeMoment =
      this.importedBeforeDateValue1 && moment(this.importedBeforeDateValue1).isValid()
        ? moment(this.importedBeforeDateValue1)
        : null;

    const col = 'ExportsAuthsDataCache';
    const aggregate = [
      {
        $match: {
          _inactive: { $ne: true },
          ...(activeAuthMoment
            ? {
                StartDT: { $lte: { $date: { v: activeAuthMoment.clone().endOf('month').toDate().toISOString() } } },
                EndDT: { $gte: { $date: { v: activeAuthMoment.clone().startOf('month').toDate().toISOString() } } },
              }
            : {}),
          ...(importedBeforeMoment
            ? {
                _ctime: { $lte: { $date: { v: importedBeforeMoment.toDate().toISOString() } } },
              }
            : {}),
        },
      },
      {
        $sort: {
          StartDT: -1,
          _ctime: -1,
        },
      },
      {
        $group: {
          _id: {
            MedicaidID: '$MedicaidID',
            Code: '$Code',
            StartDT: '$StartDT',
            EndDT: '$EndDT',
          },
          docs: {
            $push: '$$CURRENT',
          },
          last: {
            $first: '$$CURRENT',
          },
          count: {
            $sum: 1.0,
          },
        },
      },
      {
        $sort: {
          'last.StartDT': -1,
          'last._ctime': -1,
        },
      },
    ];

    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const q = new MongoLoadOptionsConverter().convert(loadOptions);
        return gqlMongoLoad(self.dss, col, loadOptions, [
          // ...(!isEmpty(q.where) ? [{$match: q.where}] : []),
          ...aggregate,
        ]).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        const q = new MongoLoadOptionsConverter().convert(loadOptions);
        return gqlMongoCount(self.dss, col, loadOptions, [
          // ...(!isEmpty(q.where) ? [{$match: q.where}] : []),
          ...aggregate,
        ]).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
    });
    const dso: DataSourceOptions = {
      store,
      // sort: [{selector: '_date', desc: true}],
      // postProcess: (data: Array<any>): Array<any> => {
      //   return data;
      // },
    } as DataSourceOptions;
    return dso;
  }

  private buildDataSource2() {
    const self = this;

    const activeAuthMoment =
      this.selectedDateValue2 && moment(this.selectedDateValue2).isValid()
        ? utc(moment(this.selectedDateValue2).format('YYYY-MM-DD'))
        : null;

    // const col = 'ExportsConsumersCache';
    // const aggregate = [
    //   {
    //     $match: {
    //       _broker: 'NAVINET.AUTH',
    //     },
    //   },
    // ];

    const col = 'ExportsAuthsDataCache';
    const aggregate = [
      {
        $match: {
          _inactive: { $ne: true },

          ...(this.selectedCodes2.length > 0
            ? {
                Code: { $in: this.selectedCodes2 },
              }
            : {}),

          ...(activeAuthMoment
            ? {
                StartDT: { $lte: { $date: { v: activeAuthMoment.clone().endOf('month').toDate().toISOString() } } },
                EndDT: { $gte: { $date: { v: activeAuthMoment.clone().startOf('month').toDate().toISOString() } } },
              }
            : {}),
        },
      },
      {
        $sort: {
          StartDT: -1,
          _ctime: -1,
        },
      },
      {
        $group: {
          _id: {
            _broker: '$_broker',
            _clientId: '$_clientId',
          },
          // docs: {
          //   $push: '$$CURRENT',
          // },
          last: {
            $first: '$$CURRENT',
          },
        },
      },
      {
        $sort: {
          'last.StartDT': -1,
          'last._ctime': -1,
        },
      },
    ];

    const store = new CustomStore({
      key: '_id',
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const q = new MongoLoadOptionsConverter().convert(loadOptions);
        return gqlMongoLoad(self.dss, col, loadOptions, [
          // ...(!isEmpty(q.where) ? [{$match: q.where}] : []),
          ...aggregate,
        ]).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        const q = new MongoLoadOptionsConverter().convert(loadOptions);
        return gqlMongoCount(self.dss, col, loadOptions, [
          // ...(!isEmpty(q.where) ? [{$match: q.where}] : []),
          ...aggregate,
        ]).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      update: async (key: any | string | number, values: any): Promise<any> => {
        console.log(key, values);

        await this.dss
          .getApi<AuthServiceApi>(AuthService)
          .assignSystemClientToAuthOne(key._broker, key._clientId, values.last._tenantId, headersAllTenantsAppend)
          .toPromise();
      },
    });

    const dso: DataSourceOptions = {
      store,
      // sort: [{selector: '_date', desc: true}],
      // postProcess: (data: Array<any>): Array<any> => {
      //   return data;
      // },
    } as DataSourceOptions;
    return dso;
  }

  getDso3(authDoc) {
    const self = this;

    if (!this.dso3[authDoc._clientId]) {
      const col = 'ExportsAuthsDataCache';
      const aggregate = [
        {
          $match: {
            _inactive: { $ne: true },
            _clientId: authDoc._clientId,
          },
        },
        {
          $sort: {
            StartDT: -1,
            _ctime: -1,
          },
        },
      ];

      const store = new CustomStore({
        useDefaultSearch: true,
        cacheRawData: false,
        load: async (loadOptions: LoadOptions): Promise<any> => {
          const q = new MongoLoadOptionsConverter().convert(loadOptions);
          return gqlMongoLoad(self.dss, col, loadOptions, [
            // ...(!isEmpty(q.where) ? [{$match: q.where}] : []),
            ...aggregate,
          ]).toPromise();
        },
        totalCount: async (loadOptions: LoadOptions): Promise<number> => {
          const q = new MongoLoadOptionsConverter().convert(loadOptions);
          return gqlMongoCount(self.dss, col, loadOptions, [
            // ...(!isEmpty(q.where) ? [{$match: q.where}] : []),
            ...aggregate,
          ]).toPromise();
        },
        byKey: async (key: any | string | number): Promise<any> => {
          return gqlMongoByKey(self.dss, col, key).toPromise();
        },
      });

      const dso: DataSourceOptions = {
        store,
        // sort: [{selector: '_date', desc: true}],
        // postProcess: (data: Array<any>): Array<any> => {
        //   return data;
        // },
      } as DataSourceOptions;

      this.dso3[authDoc._clientId] = dso;
    }

    return this.dso3[authDoc._clientId];
  }

  private buildFacilityDataSource() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = {
      where: { type: { inq: ['MEALS'] } },
      order: ['typeOrder DESC', 'type', 'shortname'],
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return dso;
  }

  private buildConsumerDataSource() {
    const so = this.dss.getStoreOptions(Consumer, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;

    return dso;
  }

  filter1() {
    this.$filterEvent1$.next(true);
  }

  filter2() {
    this.$filterEvent2$.next(true);
  }

  filter3() {
    this.grid2FilterValue = ['last._tenantId', '=', null];
  }

  grid1_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid1_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      name: 'createAuth',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'always',
      options: {
        icon: 'fas fa-plus',
        text: 'Create Auth',
        hint: 'Manual create auth',
        onClick: () => this.editAuthorization(),
      },
    });
  }

  btnEditAuthClick(e, cellInfo) {
    this.editAuthorization(cellInfo.data.last);
  }

  btnDropAuthClick(e, cellInfo) {
    const title = 'Confirm delete';
    const msg = 'Are you sure want to drop auth?';

    void from(confirm(msg, title))
      .pipe(
        first(),
        filter(identity),
        switchMap(() =>
          this.dss
            .getApi<AuthServiceApi>(AuthService)
            .dropAuthorization(cellInfo.data.last._id, headersAllTenantsAppend)
            .pipe(
              tap(() => this.grid1.instance.refresh()),
              catchError(err => of(notify(err.message, 'error', 5000))),
            ),
        ),
      )
      .toPromise();
  }

  editAuthorization(auth?: any) {
    const dialogRef = this.dialog.open<DlgAuthEditComponent>(DlgAuthEditComponent, {
      minWidth: 650,
      data: { auth },
    });

    dialogRef
      .afterClosed()
      .pipe(
        tap(console.log),
        tap(async res => {
          if (!!res) {
            this.grid1.instance.refresh();
          }
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  grid1_onContextMenuPreparing(e) {}

  grid1_onCellPrepared(e) {
    // console.log(e);
    const dataField = (e.column.dataField || '').split('.')[1] || '';

    if (e.rowType === 'data' && !dataField.startsWith('_')) {
      const docs = orderBy(e.data.docs, ['StartDT', '_ctime']);
      const curIdx = docs.findIndex(d => d._id === e.data.last._id);
      const prevIdx = curIdx - 1;

      // console.log(e, docs);
      if (prevIdx >= 0 && docs[curIdx][dataField] !== docs[prevIdx][dataField])
        (e.cellElement as HTMLElement).style.color = 'blue';
      // else if (prevIdx < 0)
      //   (e.cellElement as HTMLElement).style.color = 'blue';
    }

    if (e.rowType === 'data' && oc(e).data.last._valid() === false) {
      if (e.data.last._invalidObj[dataField]) (e.cellElement as HTMLElement).style.background = 'lightsalmon';
    }
  }

  grid1_detail_onCellPrepared(e, docs: any[]) {
    const dataField = e.column.dataField || '';

    if (e.rowType === 'data' && !dataField.startsWith('_')) {
      docs = orderBy(docs, ['StartDT', '_ctime']);

      const curIdx = docs.findIndex(d => d._id === e.data._id);
      const prevIdx = curIdx - 1;

      // console.log(e, docs);
      if (prevIdx >= 0 && docs[curIdx][dataField] !== docs[prevIdx][dataField])
        (e.cellElement as HTMLElement).style.color = 'blue';
    }

    if (e.rowType === 'data' && e.data._valid === false) {
      if (e.data._invalidObj[dataField]) (e.cellElement as HTMLElement).style.background = 'lightsalmon';
    }
  }

  grid2_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid2_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      name: 'showUnassignedClients',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'always',
      options: {
        icon: 'fas fa-users',
        text: 'Unassigned Clients',
        hint: 'Show not assigned clients',
        onClick: () => this.filter3(),
      },
    });
  }

  grid2_onContextMenuPreparing(e) {}

  grid2_onCellPrepared(e) {}

  grid2_detail_onCellPrepared(e, docs: any[]) {
    if (e.rowType === 'data' && e.data._valid === false) {
      if (e.data._invalidObj[e.column.dataField]) (e.cellElement as HTMLElement).style.background = 'lightsalmon';
    }
  }

  tenantDropDown_onValueChanged(cellInfo, e) {
    cellInfo.setValue(e.value);
  }
}
