import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { compact, flatten, isNil } from 'lodash-es';
import isEmpty from 'lodash-es/isEmpty';
import uniq from 'lodash-es/uniq';
import moment, { utc } from 'moment';
import { BehaviorSubject, combineLatest, iif, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { ALoadOptionsConverter } from '../../../../../shared/classes/loopback-custom-store/generic/load-options-converters/ALoadOptionsConverter';
import { hasAmbTrips, headersAllTenantsAppend } from '../../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../../shared/modules/my-common/services/pusher.service';
import { StateStoreService } from '../../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../../shared/modules/ui/components/abstract/a-base.component';
import { DlgSelectSignatureComponent } from '../../../../../shared/modules/ui/components/dlg-select-signature/dlg-select-signature.component';
import { GridHelperService } from '../../../../../shared/modules/ui/services/grid-helper.service';
import { PushNotificationsService } from '../../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../../shared/modules/ui/services/ui.service';
import { Consumer, ConsumerApi, Employee, EmployeeApi, Facility, FacilityApi, LoggerService, MyUtilsApi, Signature, SignatureApi, Vehicle, VehicleApi, } from '../../../../../shared/sdk';
import { DlgEditServiceTypeComponent } from '../../../../billing/dialogs/dlg-edit-service-type/dlg-edit-service-type.component';
import { DlgEditTimesComponent } from '../../../../billing/dialogs/dlg-edit-times/dlg-edit-times.component';
import { HelperService as ConsumerHelperService } from '../../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../../employee/services/helper.service';
import { DIRECTION_MARKERS, SERVICE_TYPE } from '../../../../trip-manifest/classes/enums';
import { DlgSelectDriverComponent } from '../../../../trip-manifest/dialogs/dlg-select-driver/dlg-select-driver.component';
import { DlgSelectVehicleComponent } from '../../../../trip-manifest/dialogs/dlg-select-vehicle/dlg-select-vehicle.component';
export class SignaturesValidationComponent extends ABaseComponent {
    constructor(logger, router, ui, config, dss, sss, gridHelper, signatureApi, facilityApi, utilsApi, consumerApi, vehicleApi, employeeApi, consumerHelper, employeeHelper, pusher, notification, http, dialog) {
        super(logger);
        this.logger = logger;
        this.router = router;
        this.ui = ui;
        this.config = config;
        this.dss = dss;
        this.sss = sss;
        this.gridHelper = gridHelper;
        this.signatureApi = signatureApi;
        this.facilityApi = facilityApi;
        this.utilsApi = utilsApi;
        this.consumerApi = consumerApi;
        this.vehicleApi = vehicleApi;
        this.employeeApi = employeeApi;
        this.consumerHelper = consumerHelper;
        this.employeeHelper = employeeHelper;
        this.pusher = pusher;
        this.notification = notification;
        this.http = http;
        this.dialog = dialog;
        this.$items$ = new BehaviorSubject([]);
        this.$changes$ = new BehaviorSubject([]);
        this.dso$ = of(new DataSource([]));
        this.changes = [];
        this.editRowKey = null;
        this.$filterEvent$ = new BehaviorSubject(false);
        this.facilityDso$ = of([]);
        this.consumerDso$ = of([]);
        this.employeeDso$ = of([]);
        this.vehicleDso$ = of([]);
        this.facilitySubDso$ = of([]);
        this.consumerSubDso$ = of([]);
        this.employeeSubDso$ = of([]);
        this.vehicleSubDso$ = of([]);
        this.tripDsoMap = new Map();
        this.serviceTypes = [...Object.values(SERVICE_TYPE), ...(hasAmbTrips() ? ['AMB_TRIP'] : [])];
        this.directionMarkers = [...Object.values(DIRECTION_MARKERS), ...(hasAmbTrips() ? ['TO_AMB', 'FROM_AMB'] : [])];
        this.validationStates = [
            { v: 'PENDING', t: 'Not Validated' },
            { v: 'VALID', t: 'Valid' },
            { v: 'INVALID', t: 'Invalid' },
            { v: 'ISSUES', t: 'Issues' },
            { v: 'FULL_AUTO_VALIDATED', t: 'Full Auto Validated' },
            { v: 'PARTIAL_AUTO_VALIDATED', t: 'Partial Auto Validated' },
        ];
        this.selectedFromValue = moment().toDate();
        this.selectedToValue = moment().toDate();
        this.tripIdTooltipVisibleMap = {};
        this.tripTooltipVisibleMap = {};
        this.popover = {};
        this.vehicleDsMap = {};
        this.driverDsMap = {};
        this.toolTipVisible = {};
        this.toolTipData = {};
        this.grid_stateStoring = {
            enabled: true,
            type: 'localStorage',
            storageKey: '91dc7af5-f50c-4580-941f-0ff480ee4c65',
        };
        this.dso$ = this.$items$.pipe(map(items => {
            const aso = {
                key: Signature.getModelDefinition().idName,
                data: items,
            };
            // console.log(recs.length);
            return new DataSource(new ArrayStore(aso));
        }), tap(() => {
            if (this.$items$.getValue()) {
                this.facilitySubDso$ = this.buildFacilitySubDataSource();
                this.consumerSubDso$ = this.buildConsumerSubDataSource();
                this.employeeSubDso$ = this.buildEmployeeSubDataSource();
                this.vehicleSubDso$ = this.buildVehicleSubDataSource();
            }
        }));
        this.facilityDso$ = this.buildFacilityDataSource();
        this.consumerDso$ = this.buildConsumerDataSource();
        this.employeeDso$ = this.buildEmployeeDataSource();
        this.vehicleDso$ = this.buildVehicleDataSource();
    }
    ngOnInit() {
        super.ngOnInit();
        this.$filterEvent$
            .pipe(filter(arg => arg), tap(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.grid.instance.endCustomLoading();
            this.grid.instance.beginCustomLoading('Filtering...');
            yield this.grid.instance.deselectAll();
            this.grid.instance.clearSelection();
            this.$items$.next([]);
        })), switchMap(() => this.buildDataSource().pipe(catchError(err => {
            notify(err.message, 'error', 5000);
            return of([]);
        }))), tap(items => {
            this.$items$.next(items);
            this.grid.instance.refresh();
        }), tap(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.grid.instance.deselectAll();
            this.grid.instance.clearSelection();
            this.grid.instance.clearFilter();
            this.grid.instance.endCustomLoading();
        })), takeUntil(this.$onDestroy$))
            .subscribe();
        // this.$changes$.pipe(
        //   switchMap(changes => from(changes).pipe(
        //     switchMap(change => {
        //       console.log(change);
        //       return of();
        //
        //       // return this.dss.getApi<SignatureApi>(Signature).patchAttributes(
        //       //   e.row.data.id,
        //       //   {employeeId: eId},
        //       //   headersAllTenantsAppend,
        //       // ).pipe(
        //       //   tap(() => {
        //       //     as.push([{
        //       //       type: 'update', key: e.row.key,
        //       //       data: {employeeId: eId},
        //       //     }]);
        //       //     this.employeeSubDso$ = this.buildEmployeeSubDataSource();
        //       //   }),
        //       // );
        //     })
        //   )),
        //   takeUntil(this.$onDestroy$),
        // ).subscribe();
    }
    tripId_selectedItemChange(cellInfo, e) {
        // console.log(cellInfo, e);
        const ds = this.grid.instance.getDataSource();
        const as = ds.store();
        as.push([{ type: 'update', data: { _trip: e }, key: cellInfo.key }]);
    }
    // region data sources
    buildDataSource() {
        return of(true).pipe(tap(() => this.grid.instance.beginCustomLoading('Filtering...')), map(() => {
            const _from = this.selectedFromValue;
            const _to = this.selectedToValue;
            const fromMoment = _from && moment(_from);
            const toMoment = _to && moment(_to).add(1, 'days');
            if (!fromMoment || !toMoment) {
                throw new Error('Period should be defined');
            }
            if (toMoment.diff(fromMoment, 'months') > 1) {
                throw new Error('Period should be less or equal to 1 month');
            }
            const strFrom = fromMoment && fromMoment.format('YYYY-MM-DD');
            const strTo = toMoment && toMoment.format('YYYY-MM-DD');
            return {
                fromIncl: strFrom,
                toExcl: strTo,
                serviceType: this.serviceType,
                directionMarker: this.directionMarker,
                validationState: this.validationState,
                hasTrip: this.hasTrip,
                // tripDayClosed: this.tripDayClosed,
                dayServiceMarker: this.dayServiceMarker,
                hasMissingData: this.hasMissingData,
                possibleIssues: this.possibleIssues,
                mci: this.mci,
                // mco: this.mco,
                // broker: this.broker,
                // hasClaim: this.hasClaim,
                facilityIds: this.facilityId ? [this.facilityId] : [],
                driverIds: this.driverId ? [this.driverId] : [],
                clientIds: this.clientId ? [this.clientId] : [],
            };
        }), switchMap(fltr => this.pusher.rpc('GET_SERVICES', Object.assign({}, fltr, { useRunService: true }), true, headersAllTenantsAppend)), switchMap(url => this.http.get(url, {
            responseType: 'json',
            withCredentials: false,
        })), map((recs) => {
            this.tripDsoMap.clear();
            recs.forEach(r => {
                this.tripDsoMap.set(r.id, r._trips);
                r.getMissingData = function () {
                    const _self = this;
                    _self._missing = {
                        consumerId: !_self.consumerId ? 'Missing Client' : undefined,
                        employeeId: !_self.employeeId ? 'Missing Driver' : undefined,
                        vehicleId: !_self.vehicleId ? 'Missing Vehicle' : undefined,
                        arrivedTime: isEmpty(_self.arrivedTime) ? 'Missing Arrived Time' : undefined,
                        pickupTime: isEmpty(_self.pickupTime) ? 'Missing PU Time' : undefined,
                        dropoffTime: isEmpty(_self.dropoffTime) ? 'Missing DO Time' : undefined,
                        imgFileId: isEmpty(_self.imgFileId) ? 'Missing Signature Image' : undefined,
                        marker: isEmpty(_self.marker) ? 'Missing Direction Marker' : undefined,
                    };
                    _self._missingList = Object.entries(_self._missing)
                        .map(([, t]) => t)
                        .filter(t => !isEmpty(t));
                }.bind(r);
                r.getIssueData = function () {
                    const _self = this;
                    _self.getMissingData();
                    const arrTime = oc(_self).arrivedTime() ? moment(oc(_self).arrivedTime(), 'HH:mm:ss') : null;
                    const puTime = oc(_self).pickupTime() ? moment(oc(_self).pickupTime(), 'HH:mm:ss') : null;
                    const doTime = oc(_self).dropoffTime() ? moment(oc(_self).dropoffTime(), 'HH:mm:ss') : null;
                    const lgtcPu = oc(_self)._trip.pu_time() ? moment(oc(_self)._trip.pu_time(), 'HH:mm') : null;
                    const issues = [
                        {
                            field: ['marker'],
                            issue: _self.marker === DIRECTION_MARKERS.UNKNOWN ? 'Direction Marker is UNKNOWN' : undefined,
                        },
                        {
                            field: ['pickupTime', 'dropoffTime'],
                            issue: puTime && doTime && doTime.diff(puTime, 'minutes', true) < 5
                                ? 'DO and PU diff less than 5 min'
                                : undefined,
                        },
                        {
                            field: ['pickupTime', 'dropoffTime'],
                            issue: puTime && doTime && doTime.diff(puTime, 'minutes', true) > 120
                                ? 'DO and PU diff more than 120 min'
                                : undefined,
                        },
                        {
                            field: ['pickupTime'],
                            issue: puTime && lgtcPu && Math.abs(lgtcPu.diff(puTime, 'minutes', true)) > 60
                                ? `Broker PU (${oc(_self)._trip.pu_time()}) and Driver PU diff more than 60 min`
                                : undefined,
                        },
                    ];
                    _self._issueData = issues;
                    _self._issueFields = flatten(issues.filter(i => i.issue).map(i => i.field)).reduce((o, f) => (Object.assign({}, o, { [f]: true })), {});
                    _self._issueList = [..._self._missingList, ...issues.map(i => i.issue)].filter(t => !isEmpty(t));
                }.bind(r);
                r.getClaimStatus = function () {
                    const _self = this;
                    return oc(_self)._claim.STATUS() || oc(_self)._claim['Reimbursement Status']();
                }.bind(r);
                r.getLastName = function () {
                    const _self = this;
                    return (
                    // oc(_self)._trip['Member\'s Last Name']() ||
                    // oc(_self)._trip['Member Last Name']() ||
                    // oc(_self)._trip._lastname() ||
                    oc(_self)._client.lastname());
                }.bind(r);
                r.getFirstName = function () {
                    const _self = this;
                    return (
                    // oc(_self)._trip['Member\'s First Name']() ||
                    // oc(_self)._trip['Member First Name']() ||
                    // oc(_self)._trip._firstname() ||
                    oc(_self)._client.firstname());
                }.bind(r);
                r.getMci = function () {
                    const _self = this;
                    return (
                    // oc(_self)._trip._mci() ||
                    oc(_self)._client.mci());
                }.bind(r);
                r.getServiceType = function () {
                    const _self = this;
                    return oc(_self).meta.serviceType(SERVICE_TYPE.PARATRANSIT);
                }.bind(r);
                r.getMco = function () {
                    const _self = this;
                    return oc(_self)._client.mco();
                }.bind(r);
                r.getOrigin = function () {
                    const _self = this;
                    return oc(_self)._rec.o();
                }.bind(r);
                r.getDestination = function () {
                    const _self = this;
                    return oc(_self)._rec.d();
                }.bind(r);
                r.getNote = function () {
                    const _self = this;
                    const notes = [];
                    if (oc(_self).meta.skipEmployeeId())
                        notes.push('Skipped by driver');
                    return notes.join('.\n');
                }.bind(r);
                r.getTimes = function () {
                    const _self = this;
                    _self.getIssueData();
                }.bind(r);
                r.getIssueData();
            });
            return recs
                .filter(r => isNil(this.hasMissingData) || this.hasMissingData === r._missingList.length > 0)
                .filter(r => isNil(this.possibleIssues) || this.possibleIssues === r._issueList.length > 0);
        }), tap(() => this.grid.instance.endCustomLoading()), takeUntil(this.$onDestroy$));
    }
    buildFacilityDataSource() {
        const so = this.dss.getStoreOptions(Facility, undefined, false);
        so.customFilter = {
            where: { type: { inq: ['BASE', 'ADC', 'MEALS'] } },
            order: ['typeOrder DESC', 'type', 'shortname'],
        };
        const dso = {
            store: new CustomStore(so),
        };
        return of(dso);
    }
    buildFacilitySubDataSource() {
        const so = this.dss.getStoreOptions(Facility, undefined, false);
        so.customFilter = {
            where: { type: { inq: ['BASE', 'ADC', 'MEALS'] } },
            order: ['typeOrder DESC', 'type', 'shortname'],
        };
        const dso = {
            store: new CustomStore(so),
            filter: [
                ALoadOptionsConverter.inq('id', uniq((this.$items$.getValue() || []).map(r => r.tenantId))),
            ],
        };
        return of(dso);
    }
    buildConsumerDataSource() {
        const so = this.dss.getStoreOptions(Consumer, undefined, false);
        so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };
        const dso = {
            store: new CustomStore(so),
        };
        return of(dso);
    }
    buildConsumerSubDataSource() {
        const so = this.dss.getStoreOptions(Consumer, undefined, false);
        so.customHeaders = headersAllTenantsAppend;
        const dso = {
            store: new CustomStore(so),
            filter: [['id', 'inq', uniq(this.$items$.getValue().map(r => r.consumerId))]],
        };
        return of(dso);
    }
    buildEmployeeDataSource() {
        const so = this.dss.getStoreOptions(Employee, undefined, false);
        so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };
        const dso = {
            store: new CustomStore(so),
        };
        return of(dso);
    }
    buildEmployeeSubDataSource() {
        const so = this.dss.getStoreOptions(Employee, undefined, false);
        so.customHeaders = headersAllTenantsAppend;
        const dso = {
            store: new CustomStore(so),
            filter: [['id', 'inq', uniq(this.$items$.getValue().map(r => r.employeeId))]],
        };
        return of(dso);
    }
    buildVehicleDataSource() {
        const so = this.dss.getStoreOptions(Vehicle, undefined, false);
        // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
        so.customHeaders = headersAllTenantsAppend;
        const dso = {
            store: new CustomStore(so),
        };
        return of(dso);
    }
    buildVehicleSubDataSource() {
        const so = this.dss.getStoreOptions(Vehicle, undefined, false);
        so.customHeaders = headersAllTenantsAppend;
        const dso = {
            store: new CustomStore(so),
            filter: [['id', 'inq', uniq(this.$items$.getValue().map(r => r.vehicleId))]],
        };
        return of(dso);
    }
    //endregion
    filter() {
        this.$filterEvent$.next(true);
    }
    vehicleData(id) {
        const self = this;
        if (!id) {
            return of({});
        }
        if (!self.vehicleDsMap[id]) {
            self.vehicleDsMap[id] = {};
        }
        if (!self.vehicleDsMap[id].fullInstance) {
            const inst$ = self.vehicleApi.findById(id, {}, headersAllTenantsAppend);
            self.vehicleDsMap[id].fullInstance = inst$;
        }
        return self.vehicleDsMap[id].fullInstance;
    }
    driverData(id) {
        const self = this;
        if (!id) {
            return of({});
        }
        if (!self.driverDsMap[id]) {
            self.driverDsMap[id] = {};
        }
        if (!self.driverDsMap[id].fullInstance) {
            const inst$ = self.employeeApi.findById(id, {
                include: [{ person: { contact: ['addresses', 'phones', 'emails'] } }],
            }, headersAllTenantsAppend);
            self.driverDsMap[id].fullInstance = inst$;
        }
        return self.driverDsMap[id].fullInstance;
    }
    facility_onSelectionChanged(e) {
        // console.log(e.selectedItem);
        this.consumerDso$ = this.buildConsumerDataSource();
        this.employeeDso$ = this.buildEmployeeDataSource();
        this.vehicleDso$ = this.buildVehicleDataSource();
    }
    toggleTooltip(cellInfo, hover) {
        this.toolTipVisible[cellInfo.column.name][cellInfo.data.id] = hover;
    }
    grid_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
        // {
        //   // disabled: this.$showFromBroker$.value,
        //   name: 'buildBatch',
        //   locateInMenu: 'auto',
        //   widget: 'dxButton',
        //   location: 'after',
        //   sortIndex: 30,
        //   showText: 'inMenu',
        //   options: {
        //     icon: 'fas fa-folder-plus',
        //     text: 'Build Paratransit Batch',
        //     hint: 'Build Paratransit Claims Batch',
        //     onClick: this.grid_toolbar_buildBatch_onClick.bind(this),
        //   }
        // },
        // {
        //   // disabled: this.$showFromBroker$.value,
        //   name: 'buildBatch',
        //   locateInMenu: 'auto',
        //   widget: 'dxButton',
        //   location: 'after',
        //   sortIndex: 30,
        //   showText: 'inMenu',
        //   options: {
        //     icon: 'fas fa-folder-plus',
        //     text: 'Build Meals Batch',
        //     hint: 'Build Meals Claims Batch',
        //     onClick: this.grid_toolbar_buildMealsBatch_onClick.bind(this),
        //   }
        // },
        // {
        //   // disabled: this.$showFromBroker$.value,
        //   name: 'buildMealsReq',
        //   locateInMenu: 'auto',
        //   widget: 'dxButton',
        //   location: 'after',
        //   sortIndex: 30,
        //   showText: 'inMenu',
        //   options: {
        //     icon: 'fas fa-build',
        //     text: 'Meals Trips Req',
        //     hint: 'Build Meals Trips Request Files',
        //     onClick: this.grid_toolbar_buildMealsReq_onClick.bind(this),
        //   }
        // },
        // {
        //   // disabled: this.$showFromBroker$.value,
        //   name: 'buildSchoolReport',
        //   locateInMenu: 'auto',
        //   widget: 'dxButton',
        //   location: 'after',
        //   sortIndex: 30,
        //   showText: 'inMenu',
        //   options: {
        //     icon: 'fas fa-file-signature',
        //     text: 'Build Report for School',
        //     hint: 'Build School Report',
        //     onClick: this.grid_toolbar_buildSchoolReport_onClick.bind(this),
        //   }
        // },
        {
            // disabled: this.$showFromBroker$.value,
            name: 'selectAllValid',
            locateInMenu: 'auto',
            widget: 'dxButton',
            location: 'before',
            sortIndex: 30,
            showText: 'always',
            options: {
                icon: 'fas fa-select',
                text: 'Select All Trustworthy Trips',
                hint: 'Select All Trustworthy Trips',
                onClick: this.grid_toolbar_selectAllValid_onClick.bind(this),
            },
        }, {
            // disabled: this.$showFromBroker$.value,
            name: 'setValidSelected',
            locateInMenu: 'auto',
            widget: 'dxButton',
            location: 'before',
            sortIndex: 30,
            showText: 'always',
            options: {
                icon: 'fas fa-valid',
                text: 'Validate Selected Trips',
                hint: 'Validate Selected Trips',
                onClick: this.grid_toolbar_validateSelectedService_onClick.bind(this),
            },
        });
    }
    grid_onContextMenuPreparing(e) {
        if (e.row && e.row.rowType === 'data' && !e.row.isEditing) {
            // this.logger.log(e);
            // const eId = get(e.row.data, HelperService.REC_FIELD_MAP.employeeId);
            // const cId = get(e.row.data, HelperService.REC_FIELD_MAP.consumerId);
            const ds = this.grid.instance.getDataSource();
            const as = ds.store();
            e.items = [
                {
                    text: 'Change Signature Image',
                    onItemClick: () => {
                        void this.dialog
                            .open(DlgSelectSignatureComponent, {
                            hasBackdrop: true,
                            data: {
                                signature: e.row.data,
                            },
                        })
                            .afterClosed()
                            .pipe(switchMap(v => iif(() => !!oc(v).data(), of(v).pipe(tap(() => this.ui.showLoading()), switchMap(res => {
                            // if (this.data.signature) {
                            return this.dss
                                .getApi(Signature)
                                .patchAttributes(e.row.data.id, { imgFileId: res.data.imgFileId }, headersAllTenantsAppend)
                                .pipe(tap(() => {
                                as.push([
                                    {
                                        type: 'update',
                                        key: e.row.key,
                                        data: { imgFileId: res.data.imgFileId },
                                    },
                                ]);
                            }));
                        }), catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading())))))
                            .toPromise();
                    },
                },
                {
                    text: 'Change Arr/PU/DO Time',
                    onItemClick: () => {
                        void this.dialog
                            .open(DlgEditTimesComponent, {
                            hasBackdrop: true,
                            data: {
                                signature: e.row.data,
                            },
                        })
                            .afterClosed()
                            .pipe(switchMap(v => iif(() => !!v, of(v).pipe(tap(() => this.ui.showLoading()), map(res => ({
                            scheduledTime: res.scheduledTime ? moment(res.scheduledTime).format('HH:mm:ss') : null,
                            arrivedTime: res.arrivedTime ? moment(res.arrivedTime).format('HH:mm:ss') : null,
                            pickupTime: res.pickupTime ? moment(res.pickupTime).format('HH:mm:ss') : null,
                            dropoffTime: res.dropoffTime ? moment(res.dropoffTime).format('HH:mm:ss') : null,
                        })), 
                        // tap((res) => console.log(res)),
                        switchMap(res => {
                            return this.dss
                                .getApi(Signature)
                                .patchAttributes(e.row.data.id, {
                                arrivedTime: res.arrivedTime,
                                pickupTime: res.pickupTime,
                                dropoffTime: res.dropoffTime,
                            }, headersAllTenantsAppend)
                                .pipe(tap(() => {
                                as.push([
                                    {
                                        type: 'update',
                                        key: e.row.key,
                                        data: {
                                            arrivedTime: res.arrivedTime,
                                            pickupTime: res.pickupTime,
                                            dropoffTime: res.dropoffTime,
                                        },
                                    },
                                ]);
                            }));
                        }), catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading())))))
                            .toPromise();
                    },
                },
                {
                    text: 'Change Vehicle',
                    onItemClick: () => {
                        void this.dialog
                            .open(DlgSelectVehicleComponent, {
                            width: '450px',
                            maxHeight: '650px',
                            hasBackdrop: true,
                            data: {
                                recIds: [e.row.data.vehicleId],
                                filter: {},
                            },
                        })
                            .afterClosed()
                            .pipe(filter(keys => keys !== false && keys && keys.length === 1), tap(() => this.ui.showLoading()), map(keys => keys[0]), switchMap(vId => {
                            return this.dss
                                .getApi(Signature)
                                .patchAttributes(e.row.data.id, { vehicleId: vId }, headersAllTenantsAppend)
                                .pipe(tap(() => {
                                as.push([
                                    {
                                        type: 'update',
                                        key: e.row.key,
                                        data: { vehicleId: vId },
                                    },
                                ]);
                                this.vehicleSubDso$ = this.buildVehicleSubDataSource();
                            }));
                        }), catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading()))
                            .toPromise();
                    },
                },
                {
                    text: 'Change Driver',
                    onItemClick: () => {
                        void this.dialog
                            .open(DlgSelectDriverComponent, {
                            width: '450px',
                            maxHeight: '650px',
                            hasBackdrop: true,
                            data: {
                                recIds: [e.row.data.employeeId],
                                filter: {
                                    or: [
                                        { tenantId: e.row.data.tenantId },
                                        { tenantIds: { $json_e_c: { $: JSON.stringify(e.row.data.tenantId) } } },
                                    ],
                                },
                                skipTenantCheck: true,
                            },
                        })
                            .afterClosed()
                            .pipe(filter(keys => keys !== false && keys && keys.length === 1), tap(() => this.ui.showLoading()), map(keys => keys[0]), switchMap(eId => {
                            return this.dss
                                .getApi(Signature)
                                .patchAttributes(e.row.data.id, { employeeId: eId }, headersAllTenantsAppend)
                                .pipe(tap(() => {
                                as.push([
                                    {
                                        type: 'update',
                                        key: e.row.key,
                                        data: { employeeId: eId },
                                    },
                                ]);
                                this.employeeSubDso$ = this.buildEmployeeSubDataSource();
                            }));
                        }), catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading()))
                            .toPromise();
                    },
                },
                {
                    text: 'Change Service Type',
                    onItemClick: () => {
                        void this.dialog
                            .open(DlgEditServiceTypeComponent, {
                            hasBackdrop: true,
                            data: {
                                signature: e.row.data,
                            },
                        })
                            .afterClosed()
                            .pipe(switchMap(v => iif(() => !!v, of(v).pipe(tap(() => this.ui.showLoading()), 
                        // tap((st) => console.log(st)),
                        switchMap(st => {
                            return this.dss
                                .getApi(Signature)
                                .updateServiceType(e.row.data.id, st, headersAllTenantsAppend)
                                .pipe(tap(inst => {
                                as.push([
                                    {
                                        type: 'update',
                                        key: e.row.key,
                                        data: {
                                            marker: inst.marker,
                                            meta: inst.meta,
                                            vServiceType: inst.vServiceType,
                                        },
                                    },
                                ]);
                            }));
                        }), catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading())))))
                            .toPromise();
                    },
                },
            ];
        }
    }
    grid_onCellPrepared(e) {
        // console.log(e);
        if (e.rowType === 'header') {
            // console.log(e);
            if (e.column.name && !this.toolTipVisible[e.column.name]) {
                this.toolTipVisible[e.column.name] = {};
                this.toolTipData[e.column.name] = {};
            }
        }
        if (e.rowType === 'data') {
            if (e.data._issueList.length) {
                e.cellElement.title = e.data._issueList.map(itm => '· ' + itm).join('\n');
            }
            if (oc(e).data._valid() && e.data._issueList.length === 0) {
                e.cellElement.classList.add('cell-blue');
                e.cellElement.title = [
                    'Client for this day has only 2 trips and 2 tripIDs',
                    'Trips for this client have proper assignment of flags TO_ADC and FROM_ADC and those alig with PUDO Time',
                ]
                    .map(itm => '· ' + itm)
                    .join('\n');
            }
            if (Object.entries(e.data._missing)
                .filter(([p, t]) => t)
                .map(([p, t]) => ({
                arrivedTime: 'getTimes',
                pickupTime: 'getTimes',
                dropoffTime: 'getTimes',
            }[p] || p))
                .includes(e.column.dataField)) {
                e.cellElement.classList.add('cell-violet');
            }
            //
            if (e.column.dataField === 'validationState') {
                if (oc(e).data.validationState() === 'VALID')
                    e.cellElement.classList.add('cell-green');
                else if (oc(e).data.validationState() === 'INVALID')
                    e.cellElement.classList.add('cell-red');
                else if (oc(e).data.validationState() === 'ISSUES')
                    e.cellElement.classList.add('cell-yellow');
            }
            if (e.column.dataField === 'consumerId' ||
                e.column.dataField === 'getMci' ||
                e.column.dataField === 'getLastName' ||
                e.column.dataField === 'getFirstName') {
                if (oc(e).data._client.status() === 'INACTIVE') {
                    e.cellElement.classList.add('cell-yellow');
                    e.cellElement.title = 'INACTIVE Client';
                }
            }
        }
    }
    grid_onSaving(e) {
        // console.log('grid_onSaving:', e);
        // this.$changes$.next(e.changes);
        // this.grid.instance.repaint();
        // e.cancel = true;
        this.ui.showLoading();
        e.promise = of(e.changes)
            .pipe(
        // tap(console.log),
        // tap(() => (e.component as DxDataGrid).beginCustomLoading('Updating...')),
        tap(() => this.ui.showLoading()), switchMap((changes) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield Promise.all(changes
                .filter(change => change.type === 'update')
                .map((change) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const ds = this.grid.instance.getDataSource();
                const as = ds.store();
                const item = yield as.byKey(change.key);
                yield this.dss
                    .getApi(Signature)
                    .patchAttributes(change.key, 'validationState' in change.data && change.data.validationState === 'VALID'
                    ? Object.assign({}, change.data, { meta: Object.assign({}, item.meta, { tripId: oc(item)._trip._tripId(item.meta.tripId) }) }) : change.data, headersAllTenantsAppend)
                    .toPromise();
            })));
        })), catchError(err => of(notify(err.message, 'error', 5000))), 
        // tap(() => (e.component as DxDataGrid).endCustomLoading()),
        tap(() => this.ui.hideLoading()))
            .toPromise();
    }
    grid_onSaved(e) {
        // console.log('grid_onSaved:', e);
        // this.grid.instance.repaint();
    }
    grid_toolbar_selectAllValid_onClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.grid.instance.clearSelection();
            yield this.grid.instance.deselectAll();
            const ds = this.grid.instance.getDataSource();
            const as = ds.store();
            const keys = as.createQuery()
                .filter([
                ['_valid', '=', true],
                ['validationState', '<>', 'VALID'],
            ])
                .filter(itm => itm._issueList.length === 0)
                .select('id')
                .toArray()
                .map(itm => itm.id);
            yield this.grid.instance.selectRows(keys, false);
        });
    }
    grid_toolbar_validateSelectedService_onClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const ds = this.grid.instance.getDataSource();
            const as = ds.store();
            const selected = yield this.grid.instance.getSelectedRowsData();
            const filtered = selected.filter(i => i.validationState !== 'VALID');
            // console.log(selected);
            yield of(true)
                .pipe(tap(() => this.grid.instance.beginCustomLoading('Updating...')), switchMap(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return yield Promise.all(filtered.map((itm) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    return yield this.dss
                        .getApi(Signature)
                        .patchAttributes(itm.id, {
                        validationState: 'VALID',
                        meta: Object.assign({}, itm.meta, { tripId: oc(itm)._trip._tripId(itm.meta.tripId) }),
                    }, headersAllTenantsAppend)
                        .pipe(tap(() => {
                        as.push([
                            {
                                type: 'update',
                                key: itm.id,
                                data: {
                                    validationState: 'VALID',
                                    meta: Object.assign({}, itm.meta, { tripId: oc(itm)._trip._tripId(itm.meta.tripId) }),
                                },
                            },
                        ]);
                    }))
                        .toPromise();
                })));
            })), catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.grid.instance.endCustomLoading()))
                .toPromise();
        });
    }
    getPrevTimes(cellInfo, tooltip) {
        if (!this.toolTipData[cellInfo.column.name][cellInfo.data.id]) {
            const fromIncl = utc(cellInfo.data.vdate).startOf('week');
            const toIncl = utc(cellInfo.data.vdate).endOf('week');
            const toExcl = toIncl.clone().add(1, 'day');
            this.toolTipData[cellInfo.column.name][cellInfo.data.id] = of(cellInfo.data).pipe(switchMap(e => combineLatest([
                this.signatureApi.find({
                    where: {
                        and: [
                            { tenantId: e.tenantId },
                            { consumerId: e.consumerId },
                            { vdate: { lt: e.vdate } },
                            { weekday: utc(e.vdate).isoWeekday() - 1 },
                            { type: e.type },
                            { marker: e.marker },
                            { validationState: { nin: ['INVALID', 'PENDING'] } },
                        ],
                    },
                    order: 'vdate DESC',
                    limit: 1,
                }, headersAllTenantsAppend),
                this.signatureApi.find({
                    where: {
                        and: [
                            { vdate: { gte: fromIncl.format('YYYY-MM-DD') } },
                            { vdate: { lt: toExcl.format('YYYY-MM-DD') } },
                            { tenantId: e.tenantId },
                            { consumerId: e.consumerId },
                            { type: e.type },
                            { marker: e.marker },
                            { validationState: { nin: ['INVALID', 'PENDING'] } },
                        ],
                    },
                    order: 'vdate DESC',
                    limit: 1,
                }, headersAllTenantsAppend),
            ])), map(([signs1, signs2]) => [signs1[0], ...signs2]), map(signs => '<table>' +
                [
                    ['', ...compact(signs).map(s => utc(s.vdate).format('MM/DD ddd'))].map(i => `<td>${i}</td>`).join(''),
                    ['arr', ...compact(signs).map(s => moment(s.arrivedTime, 'HH:mm:ss').format('HH:mm'))]
                        .map(i => `<td>${i}</td>`)
                        .join(''),
                    ['pu', ...compact(signs).map(s => moment(s.pickupTime, 'HH:mm:ss').format('HH:mm'))]
                        .map(i => `<td>${i}</td>`)
                        .join(''),
                    ['do', ...compact(signs).map(s => moment(s.dropoffTime, 'HH:mm:ss').format('HH:mm'))]
                        .map(i => `<td>${i}</td>`)
                        .join(''),
                ]
                    .map(i => `<tr>${i}</tr>`)
                    .join('') +
                '</table>'), tap(() => setTimeout(() => {
                tooltip.instance.repaint();
            }, 10)), catchError(err => of('')), startWith('...'));
        }
        return this.toolTipData[cellInfo.column.name][cellInfo.data.id];
    }
}
