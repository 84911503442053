<app-content-w-header header="Authorizations">
  <dx-tab-panel class="content" height="100%" width="100%">
    <dxi-item title="Authorizations">
      <div style="display: flex; flex-direction: column; width: 100%; height: 100%">
        <mat-card class="toolbar">
          <div class="dx-fieldset" style="display: flex">
            <!--      <dx-toolbar>-->
            <!--        <dxi-item [location]="'before'" locateInMenu="auto">-->
            <!--      <div style="display: flex; align-items: center;">-->
            <!--        <dx-date-box #from-->
            <!--                     width="100px"-->
            <!--                     [(value)]="selectedDateValue"-->
            <!--        ></dx-date-box>-->
            <!--        <span>&nbsp;&ndash;&nbsp;</span>-->
            <!--        <dx-date-box #to-->
            <!--                     width="100px"-->
            <!--                     [(min)]="selectedFromValue"-->
            <!--                     [(value)]="selectedToValue"-->
            <!--        ></dx-date-box>-->
            <!--      </div>-->
            <!--        </dxi-item>-->

            <div class="dx-fieldset" style="display: flex; align-items: center">
              <span>Active auth for </span>
              <dx-date-box width="120px" displayFormat="monthAndYear" [(value)]="selectedDateValue1">
                <dxo-calendar-options maxZoomLevel="year" minZoomLevel="century"></dxo-calendar-options>
              </dx-date-box>

<!--              <dx-date-box width="100px" [(value)]="selectedDateValue1"></dx-date-box>-->
            </div>

<!--            <div class="dx-fieldset" style="display: flex; align-items: center">-->
<!--              <span>Imported before </span>-->
<!--              <dx-date-box width="100px" [(value)]="importedBeforeDateValue1"></dx-date-box>-->
<!--            </div>-->

            <!--        <dxi-item location="after" locateInMenu="auto">-->
            <div style="display: inline-block">
              <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter1()"></dx-button>
            </div>
            <!--        </dxi-item>-->

            <!--      </dx-toolbar>-->
          </div>
        </mat-card>

        <div style="flex: auto; display: flex; position: relative">
          <div style="flex: auto; position: absolute; top: 0; bottom: 0; left: 0; right: 0; display: flex">
            <dx-data-grid
              #grid1
              width="100%"
              height="100%"
              [columnResizingMode]="'widget'"
              [columnAutoWidth]="true"
              [dataSource]="dso1"
              [twoWayBindingEnabled]="false"
              [stateStoring]="grid1_stateStoring"
              [dateSerializationFormat]="null"
              (onInitialized)="grid1_onInitialized($event)"
              (onCellPrepared)="grid1_onCellPrepared($event)"
              (onToolbarPreparing)="grid1_onToolbarPreparing($event)"
              (onContextMenuPreparing)="grid1_onContextMenuPreparing($event)"
            ><dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

              <dxo-editing
                mode="none"
                [allowAdding]="false"
                [allowUpdating]="false"
                [allowDeleting]="false"></dxo-editing>

              <!--    <dxo-sorting mode="none"></dxo-sorting>-->

              <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

              <!-- region Columns -->

              <dxi-column type="buttons" caption="actions" [showInColumnChooser]="false" [fixed]="true" fixedPosition="left">
                <dxi-button template="editBtn">
                  <ng-container *dxTemplate="let cellInfo of 'editBtn'">
                    <dx-button
                      hint="Edit"
                      [icon]="'edit'"
                      [disabled]='cellInfo.data.last._broker !== "CUSTOM.AUTH"'
                      (onClick)="btnEditAuthClick($event, cellInfo)"
                    ></dx-button>
                  </ng-container>
                </dxi-button>

                <dxi-button template="dropBtn">
                  <ng-container *dxTemplate="let cellInfo of 'dropBtn'">
                    <dx-button
                      hint="Delete"
                      [icon]="'remove'"
                      (onClick)="btnDropAuthClick($event, cellInfo)"
                    ></dx-button>
<!--                      [disabled]='cellInfo.data.last._broker !== "CUSTOM.AUTH"'-->
                  </ng-container>
                </dxi-button>
              </dxi-column>

              <dxi-column
                caption="Broker"
                dataField="last._broker"
                dataType="string"
                [allowSorting]="false"></dxi-column>

              <dxi-column
                caption="MemberID"
                dataField="last.MemberID"
                dataType="string"
                [allowSorting]="false"></dxi-column>

              <dxi-column caption="MedicaidID" dataField="last.MedicaidID" dataType="string"></dxi-column>

              <dxi-column caption="Name" dataField="last.Name" dataType="string" [allowFiltering]="true"></dxi-column>

              <dxi-column caption="LastName" dataField="last.LastName" dataType="string"></dxi-column>

              <dxi-column caption="FirstName" dataField="last.FirstName" dataType="string"></dxi-column>

              <dxi-column caption="Phone" dataField="last.Phone" dataType="string" [allowSorting]="false"></dxi-column>

              <dxi-column
                caption="MemberDOB"
                dataField="last.MemberDOB"
                dataType="date"
                format="shortDate"
                [customizeText]="config.customizeShortDateText"
                [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
                [allowSorting]="false"></dxi-column>

              <dxi-column
                caption="IOType"
                dataField="last.IOType"
                dataType="string"
                [allowSorting]="false"></dxi-column>

              <dxi-column
                caption="RequestType"
                dataField="last.RequestType"
                dataType="string"
                [allowSorting]="false"></dxi-column>

              <dxi-column
                caption="RequestStatus"
                dataField="last.RequestStatus"
                dataType="string"
                [allowSorting]="false"></dxi-column>

              <dxi-column
                caption="AuthNumberFacets"
                dataField="last.AuthNumberFacets"
                dataType="string"
                [allowSorting]="false"></dxi-column>

              <dxi-column
                caption="AuthNumberUMI"
                dataField="last.AuthNumberUMI"
                dataType="string"
                [allowSorting]="false"></dxi-column>

              <dxi-column
                caption="StartDT"
                dataField="last.StartDT"
                dataType="date"
                format="shortDate"
                [customizeText]="config.customizeShortDateText"
                [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
                [allowSorting]="true"></dxi-column>

              <dxi-column
                caption="EndDT"
                dataField="last.EndDT"
                dataType="date"
                format="shortDate"
                [customizeText]="config.customizeShortDateText"
                [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
                [allowSorting]="true"></dxi-column>

              <dxi-column
                caption="AuthUnitsApproved"
                dataField="last.AuthUnitsApproved"
                dataType="number"
                [allowSorting]="false"></dxi-column>

              <dxi-column
                caption="RSProviderName"
                dataField="last.RSProviderName"
                dataType="string"
                [allowSorting]="false"></dxi-column>

              <dxi-column caption="Code" dataField="last.Code" dataType="string">
                <dxo-lookup [dataSource]="codeDso"
                            valueExpr="_id"
                            displayExpr="_id"
                ></dxo-lookup>
              </dxi-column>

              <dxi-column caption="ProviderType" dataField="last.ProviderType" dataType="string"></dxi-column>

              <dxi-column caption="ProviderName" dataField="last.ProviderName" dataType="string"></dxi-column>

              <dxi-column caption="Duration" dataField="last.Duration" dataType="number"></dxi-column>

              <dxi-column caption="Frequency" dataField="last.Frequency" dataType="string"></dxi-column>

              <dxi-column caption="Note" dataField="last.Note" dataType="string"></dxi-column>

              <dxi-column
                caption="ServiceCoordinatorName"
                dataField="last.ServiceCoordinatorName"
                dataType="string"></dxi-column>

              <dxi-column
                caption="ServiceCoordinatorPhone"
                dataField="last.ServiceCoordinatorPhone"
                dataType="string"></dxi-column>

              <dxi-column
                caption="ServiceCoordinatorEmail"
                dataField="last.ServiceCoordinatorEmail"
                dataType="string"></dxi-column>

              <dxi-column
                caption="Valid"
                dataField="last._valid"
                dataType="boolean"
                cellTemplate='valid_cell'
                [showEditorAlways]='false'
              ></dxi-column>

              <dxi-column
                caption='AuthLastModifiedDT'
                dataField='last.AuthLastModifiedDT'
                dataType='date'
                format='shortDateShortTime'
                [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

              <dxi-column
                caption="Imported Date"
                dataField="last._ctime"
                dataType="date"
                format="shortDateShortTime"
                [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

              <dxi-column
                caption="Updated Date"
                dataField="last._rtime"
                dataType="date"
                format="shortDateShortTime"
                [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

              <dxi-column
                caption="Tenant"
                dataField="last._tenantId"
              >
                <dxo-lookup [dataSource]="facilityDso" valueExpr="id" displayExpr="shortname"></dxo-lookup>
              </dxi-column>

              <dxi-column caption="Count" dataField="count" dataType="number"></dxi-column>

              <!-- endregion -->

              <!-- region Templates -->

              <ng-container *dxTemplate="let cellInfo of 'valid_cell'">
                <span [title]='cellInfo.data.last._invalidObj | json' >{{cellInfo.value ? 'true' : 'false'}}</span>
              </ng-container>

              <div *dxTemplate="let info of 'detail'">
                <dx-tab-panel>
                  <dxi-item title="History">
                    <dx-data-grid
                      [dataSource]="info.data.docs"
                      [stateStoring]="grid1_detail_stateStoring"
                      [dateSerializationFormat]="null"
                      (onCellPrepared)="grid1_detail_onCellPrepared($event, info.data.docs)">
                      <dxo-editing
                        mode="none"
                        [allowAdding]="false"
                        [allowUpdating]="false"
                        [allowDeleting]="false"></dxo-editing>

                      <!--  region Columns          -->

                      <dxi-column caption="Broker" dataField="_broker" dataType="string"></dxi-column>

                      <dxi-column caption="MemberID" dataField="MemberID" dataType="string"></dxi-column>

                      <dxi-column caption="MedicaidID" dataField="MedicaidID" dataType="string"></dxi-column>

                      <dxi-column caption="Name" dataField="Name" dataType="string"></dxi-column>

                      <dxi-column caption="LastName" dataField="LastName" dataType="string"></dxi-column>

                      <dxi-column caption="FirstName" dataField="FirstName" dataType="string"></dxi-column>

                      <dxi-column caption="Phone" dataField="Phone" dataType="string"></dxi-column>

                      <dxi-column
                        caption="MemberDOB"
                        dataField="MemberDOB"
                        dataType="date"
                        format="shortDate"
                        [customizeText]="config.customizeShortDateText"
                        [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

                      <dxi-column caption="IOType" dataField="IOType" dataType="string"></dxi-column>

                      <dxi-column caption="RequestType" dataField="RequestType" dataType="string"></dxi-column>

                      <dxi-column caption="RequestStatus" dataField="RequestStatus" dataType="string"></dxi-column>

                      <dxi-column
                        caption="AuthNumberFacets"
                        dataField="AuthNumberFacets"
                        dataType="string"></dxi-column>

                      <dxi-column caption="AuthNumberUMI" dataField="AuthNumberUMI" dataType="string"></dxi-column>

                      <dxi-column
                        caption="StartDT"
                        dataField="StartDT"
                        dataType="date"
                        format="shortDate"
                        [customizeText]="config.customizeShortDateText"
                        [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

                      <dxi-column
                        caption="EndDT"
                        dataField="EndDT"
                        dataType="date"
                        format="shortDate"
                        [customizeText]="config.customizeShortDateText"
                        [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

                      <dxi-column
                        caption="AuthUnitsApproved"
                        dataField="AuthUnitsApproved"
                        dataType="number"></dxi-column>

                      <dxi-column caption="RSProviderName" dataField="RSProviderName" dataType="string"></dxi-column>

                      <dxi-column caption="Code" dataField="Code" dataType="string"></dxi-column>

                      <dxi-column caption="ProviderType" dataField="ProviderType" dataType="string"></dxi-column>

                      <dxi-column caption="ProviderName" dataField="ProviderName" dataType="string"></dxi-column>

                      <dxi-column caption="Duration" dataField="Duration" dataType="number"></dxi-column>

                      <dxi-column caption="Frequency" dataField="Frequency" dataType="string"></dxi-column>

                      <dxi-column caption="Note" dataField="Note" dataType="string"></dxi-column>

                      <dxi-column
                        caption="ServiceCoordinatorName"
                        dataField="ServiceCoordinatorName"
                        dataType="string"></dxi-column>

                      <dxi-column
                        caption="ServiceCoordinatorPhone"
                        dataField="ServiceCoordinatorPhone"
                        dataType="string"></dxi-column>

                      <dxi-column
                        caption="ServiceCoordinatorEmail"
                        dataField="ServiceCoordinatorEmail"
                        dataType="string"></dxi-column>

                      <dxi-column
                        caption="Valid"
                        dataField="_valid"
                        dataType="boolean"
                        [showEditorAlways]='false'
                      ></dxi-column>

                      <dxi-column
                        caption='AuthLastModifiedDT'
                        dataField='AuthLastModifiedDT'
                        dataType='date'
                        format='shortDateShortTime'
                        [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

                      <dxi-column
                        caption="Imported Date"
                        dataField="_ctime"
                        dataType="date"
                        format="shortDateShortTime"
                        [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

                      <dxi-column
                        caption="Updated Date"
                        dataField="_rtime"
                        dataType="date"
                        format="shortDateShortTime"
                        [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

                      <!--  endregion          -->
                    </dx-data-grid>
                  </dxi-item>
                </dx-tab-panel>
              </div>

              <!-- endregion -->
            </dx-data-grid>
          </div>
        </div>
      </div>
    </dxi-item>

    <dxi-item title="Members">
      <div style="display: flex; flex-direction: column; width: 100%; height: 100%">
        <mat-card class="toolbar">
          <div class="dx-fieldset" style="display: flex">
            <div class="dx-fieldset" style="display: flex; align-items: center">
              <span>Active auth for </span>

              <dx-date-box width="120px" displayFormat="monthAndYear" [(value)]="selectedDateValue2">
                <dxo-calendar-options maxZoomLevel="year" minZoomLevel="century"></dxo-calendar-options>
              </dx-date-box>

<!--              <dx-date-box width="100px" [(value)]="selectedDateValue2"></dx-date-box>-->
            </div>

            <div class="dx-fieldset" style="display: flex; align-items: center">
              <span>Code </span>
              <dx-tag-box
                width="200px"
                [dataSource]="codeDso"
                valueExpr="_id"
                displayExpr="_id"
                [(value)]="selectedCodes2"
              ></dx-tag-box>
            </div>

            <!--        <dxi-item location="after" locateInMenu="auto">-->
            <div style="display: inline-block">
              <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter2()"></dx-button>
            </div>
            <!--        </dxi-item>-->

            <!--      </dx-toolbar>-->
          </div>
        </mat-card>

        <div style="flex: auto; display: flex; position: relative">
          <div style="flex: auto; position: absolute; top: 0; bottom: 0; left: 0; right: 0; display: flex">
            <dx-data-grid
              #grid2
              width="100%"
              height="100%"
              [searchPanel]="{ visible: false }"
              [headerFilter]="{ visible: true }"
              [filterRow]="{ visible: true }"
              [filterPanel]="{ visible: true }"
              [columnResizingMode]="'widget'"
              [columnAutoWidth]="true"
              [dataSource]="dso2"
              [stateStoring]="grid2_stateStoring"
              [dateSerializationFormat]="null"
              [(filterValue)]="grid2FilterValue"
              (onInitialized)="grid2_onInitialized($event)"
              (onCellPrepared)="grid2_onCellPrepared($event)"
              (onToolbarPreparing)="grid2_onToolbarPreparing($event)"
              (onContextMenuPreparing)="grid2_onContextMenuPreparing($event)"
            >

              <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

              <dxo-editing
                mode="cell"
                [allowAdding]="false"
                [allowUpdating]="true"
                [allowDeleting]="false"></dxo-editing>

              <!--    <dxo-sorting mode="none"></dxo-sorting>-->

              <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

              <!-- region Columns -->

              <dxi-column
                caption="Broker"
                dataField="last._broker"
                dataType="string"
                [allowEditing]="false"></dxi-column>

              <dxi-column
                caption="MemberID"
                dataField="last._clientId"
                dataType="string"
                [allowEditing]="false"></dxi-column>

              <dxi-column
                caption="MedicaidID"
                dataField="last._mci"
                dataType="string"
                [allowEditing]="false"></dxi-column>

              <dxi-column
                caption="LastName"
                dataField="last.LastName"
                dataType="string"
                [allowEditing]="false"></dxi-column>

              <dxi-column
                caption="FirstName"
                dataField="last.FirstName"
                dataType="string"
                [allowEditing]="false"></dxi-column>

              <dxi-column caption="Phone" dataField="last.Phone" dataType="string" [allowEditing]="false"></dxi-column>

              <dxi-column
                caption="DOB"
                dataField="last.MemberDOB"
                dataType="date"
                format="shortDate"
                [customizeText]="config.customizeShortDateText"
                [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
                [allowEditing]="false"></dxi-column>

              <dxi-column
                caption="Tenant"
                dataField="last._tenantId"
                [allowEditing]="true"
                editCellTemplate="tenantEditCellTpl">
                <dxo-lookup [dataSource]="facilityDso" valueExpr="id" displayExpr="shortname"></dxo-lookup>
              </dxi-column>

              <!--              <dxi-column caption="Consumer"-->
              <!--                          dataField="_consumerId"-->
              <!--                          [allowEditing]="false"-->
              <!--              >-->
              <!--                <dxo-lookup-->
              <!--                  [dataSource]="consumerDso"-->
              <!--                  valueExpr="id"-->
              <!--                  [displayExpr]="consumerHelper.displayExpr"-->
              <!--                ></dxo-lookup>-->
              <!--              </dxi-column>-->

              <!-- endregion -->

              <!-- region Templates -->

              <ng-container *dxTemplate="let cellInfo of 'tenantEditCellTpl'">
                <app-drop-down-grid-new
                  [valueExpr]="'id'"
                  [dataSource]="facilityDso"
                  [grid_columns]="[
                    { dataField: 'type', caption: 'type' },
                    { dataField: 'shortname', caption: 'shortname' },
                    { dataField: 'legalName', caption: 'legalName' }
                  ]"
                  [showClearButton]="true"
                  [displayExpr]="'legalName'"
                  [value]="cellInfo.data._tenantId"
                  (valueChanged)="tenantDropDown_onValueChanged(cellInfo, $event)"
                  [popup_width]="350"
                  [popup_height]="300"></app-drop-down-grid-new>
              </ng-container>

              <div *dxTemplate="let info of 'detail'">
                <dx-tab-panel width='100%'>
                  <dxi-item title="Auths">
                    <dx-data-grid
                      width='100%'
                      [dataSource]="getDso3(info.data.last)"
                      [stateStoring]="grid2_detail_stateStoring"
                      [dateSerializationFormat]="null"
                      (onCellPrepared)="grid2_detail_onCellPrepared($event, info.data.docs)"
                    >

                      <dxo-editing
                        mode="none"
                        [allowAdding]="false"
                        [allowUpdating]="false"
                        [allowDeleting]="false"
                      ></dxo-editing>

                      <dxo-grouping [contextMenuEnabled]="true"></dxo-grouping>
                      <dxo-group-panel [visible]="true"></dxo-group-panel>

                      <!--  region Columns          -->

                      <dxi-column caption="Broker" dataField="_broker" dataType="string"></dxi-column>

                      <dxi-column caption="MemberID" dataField="MemberID" dataType="string"></dxi-column>

                      <dxi-column caption="MedicaidID" dataField="MedicaidID" dataType="string"></dxi-column>

                      <dxi-column caption="Name" dataField="Name" dataType="string"></dxi-column>

                      <dxi-column caption="LastName" dataField="LastName" dataType="string"></dxi-column>

                      <dxi-column caption="FirstName" dataField="FirstName" dataType="string"></dxi-column>

                      <dxi-column caption="Phone" dataField="Phone" dataType="string"></dxi-column>

                      <dxi-column
                        caption="MemberDOB"
                        dataField="MemberDOB"
                        dataType="date"
                        format="shortDate"
                        [customizeText]="config.customizeShortDateText"
                        [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

                      <dxi-column caption="IOType" dataField="IOType" dataType="string"></dxi-column>

                      <dxi-column caption="RequestType" dataField="RequestType" dataType="string"></dxi-column>

                      <dxi-column caption="RequestStatus" dataField="RequestStatus" dataType="string"></dxi-column>

                      <dxi-column
                        caption="AuthNumberFacets"
                        dataField="AuthNumberFacets"
                        dataType="string"></dxi-column>

                      <dxi-column caption="AuthNumberUMI" dataField="AuthNumberUMI" dataType="string"></dxi-column>

                      <dxi-column
                        caption="StartDT"
                        dataField="StartDT"
                        dataType="date"
                        format="shortDate"
                        [customizeText]="config.customizeShortDateText"
                        [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

                      <dxi-column
                        caption="EndDT"
                        dataField="EndDT"
                        dataType="date"
                        format="shortDate"
                        [customizeText]="config.customizeShortDateText"
                        [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

                      <dxi-column
                        caption="AuthUnitsApproved"
                        dataField="AuthUnitsApproved"
                        dataType="number"></dxi-column>

                      <dxi-column caption="RSProviderName" dataField="RSProviderName" dataType="string"></dxi-column>

                      <dxi-column caption="Code" dataField="Code" dataType="string"></dxi-column>

                      <dxi-column caption="ProviderType" dataField="ProviderType" dataType="string"></dxi-column>

                      <dxi-column caption="ProviderName" dataField="ProviderName" dataType="string"></dxi-column>

                      <dxi-column caption="Duration" dataField="Duration" dataType="number"></dxi-column>

                      <dxi-column caption="Frequency" dataField="Frequency" dataType="string"></dxi-column>

                      <dxi-column caption='Rule' dataField='_manifest' cellTemplate='rule_cell'></dxi-column>

                      <dxi-column caption="Note" dataField="Note" dataType="string"></dxi-column>

                      <dxi-column
                        caption="ServiceCoordinatorName"
                        dataField="ServiceCoordinatorName"
                        dataType="string"></dxi-column>

                      <dxi-column
                        caption="ServiceCoordinatorPhone"
                        dataField="ServiceCoordinatorPhone"
                        dataType="string"></dxi-column>

                      <dxi-column
                        caption="ServiceCoordinatorEmail"
                        dataField="ServiceCoordinatorEmail"
                        dataType="string"></dxi-column>

                      <dxi-column
                        caption="Valid"
                        dataField="_valid"
                        dataType="boolean"
                        [showEditorAlways]='false'
                      ></dxi-column>

                      <dxi-column
                        caption='AuthLastModifiedDT'
                        dataField='AuthLastModifiedDT'
                        dataType='date'
                        format='shortDateShortTime'
                        [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

                      <dxi-column
                        caption="Imported Date"
                        dataField="_ctime"
                        dataType="date"
                        format="shortDateShortTime"
                        [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

                      <dxi-column
                        caption="Updated Date"
                        dataField="_rtime"
                        dataType="date"
                        format="shortDateShortTime"
                        [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

                      <!--  endregion          -->

                      <!-- region Templates  -->

                      <ng-container *dxTemplate="let cellInfo of 'rule_cell'">
                        <table class='rules' cellpadding='0' cellspacing='0'>
                          <tr>
                            <td>mo</td>
                            <td>tu</td>
                            <td>we</td>
                            <td>th</td>
                            <td>fr</td>
                            <td>sa</td>
                            <td>su</td>
                            <td>tot</td>
                          </tr>
                          <tr>
                            <td>{{cellInfo.value?.M}}</td>
                            <td>{{cellInfo.value?.T}}</td>
                            <td>{{cellInfo.value?.W}}</td>
                            <td>{{cellInfo.value?.Th}}</td>
                            <td>{{cellInfo.value?.F}}</td>
                            <td>{{cellInfo.value?.Sa}}</td>
                            <td>{{cellInfo.value?.Su}}</td>
                            <td>{{cellInfo.value?.Tot}}</td>
                          </tr>
                        </table>
                      </ng-container>

                      <!-- endregion -->

                    </dx-data-grid>
                  </dxi-item>
                </dx-tab-panel>
              </div>

              <!-- endregion -->
            </dx-data-grid>
          </div>
        </div>
      </div>
    </dxi-item>
  </dx-tab-panel>
</app-content-w-header>
