import { HttpHeaders } from '@angular/common/http';
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import notify from 'devextreme/ui/notify';
import fromPairs from 'lodash-es/fromPairs';
import isEmpty from 'lodash-es/isEmpty';
import last from 'lodash-es/last';
import sortBy from 'lodash-es/sortBy';
import { BehaviorSubject, combineLatest, iif, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { gqlMongoLoad } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';
import {
  Consumer,
  ConsumerApi,
  LoggerService,
  LoopBackFilter,
  MyUtilsApi,
  SignatureConsUniqImgView,
} from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { DlgSelectSignatureComponent } from '../../../../shared/modules/ui/components/dlg-select-signature/dlg-select-signature.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { SERVICE_TYPE } from '../../../trip-manifest/classes/enums';
import { CONSUMER_TRANSPORTATION_CODES } from '../../classes/enums';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-consumer-master-details',
  templateUrl: './consumer-master-details.component.html',
  styleUrls: ['./consumer-master-details.component.scss'],
})
export class ConsumerMasterDetailsComponent
  extends ABaseModelLoaderComponent<Consumer>
  implements OnInit, OnChanges, OnDestroy
{
  transpCodesMap = fromPairs(CONSUMER_TRANSPORTATION_CODES.map(({ ID, Name }) => [ID, Name]));

  modelWithDetails$: BehaviorSubject<Consumer & { _details; _brokerClient }> = new BehaviorSubject(null);

  constructor(
    protected logger: LoggerService,
    protected dss: DataSourceService,
    public helper: HelperService,
    private utilsApi: MyUtilsApi,
    private ui: UiService,
    public common: CommonService,
    protected dialog: MatDialog,
  ) {
    super(logger, dss);
  }

  protected get ModelClass(): any {
    return Consumer;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: [
        'program',
        {
          relation: 'relatedNotes',
          scope: { order: 'dateTime DESC' },
        },
        { person: { contact: ['addresses', 'phones', 'emails'] } },
        { emRelations: { person: { contact: ['addresses', 'phones', 'emails'] } } },
      ],
    };
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.model$
      .pipe(
        switchMap(model => {
          const details$ = (this.api as ConsumerApi).getForPeriodDetails(
            model.id,
            [
              SERVICE_TYPE.PARATRANSIT,
              SERVICE_TYPE.ADC_TRIP,
              SERVICE_TYPE.APPT_TRIP,
              SERVICE_TYPE.EVENT_TRIP,
              SERVICE_TYPE.SCHOOL,
              'AMB_TRIP',
            ],
            this.customHeaders,
          );

          const brokerClient$ = of(true).pipe(
            switchMap(col =>
              !isEmpty(model.mci)
                ? gqlMongoLoad(this.dss, 'ExportsConsumersCache', {
                    filter: [
                      ['_mci', '=', model.mci],
                      ['_broker', '=', model.activeBroker],
                    ],
                  })
                : of([]),
            ),
            map(brokerClients => {
              return last(
                sortBy(
                  brokerClients.filter(doc => !!doc.lastTrip),
                  ['lastTrip._date'],
                ),
              );
            }),
          );

          return combineLatest([details$, brokerClient$]).pipe(
            map(([details, brokerClient]) => ({ ...model, _details: details, _brokerClient: brokerClient })),
          );
        }),
        tap(modelWithDetails => this.modelWithDetails$.next(modelWithDetails)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  protected customHeaders(headers: HttpHeaders): HttpHeaders {
    headers = super.customHeaders(headers);
    headers = headersAllTenantsAppend(headers);
    return headers;
  }

  changeSign_onClick(e) {
    void this.dialog
      .open<
        any,
        any,
        {
          data: SignatureConsUniqImgView;
          toAllSignatures?: boolean;
          toClient?: boolean;
        }
      >(DlgSelectSignatureComponent, {
        hasBackdrop: true,
        data: {
          consumer: this.model,
        },
      })
      .afterClosed()
      .pipe(
        switchMap(v =>
          iif(
            () => !!oc(v).data(),
            of(v).pipe(
              tap(() => this.ui.showLoading()),

              switchMap(res => {
                return this.dss
                  .getApi<ConsumerApi>(Consumer)
                  .patchAttributes(this.modelId, { signImgFileId: res.data.imgFileId }, headersAllTenantsAppend)
                  .pipe(
                    tap(() => {
                      this.model.signImgFileId = res.data.imgFileId;
                    }),
                  );
              }),
              catchError(err => of(notify(err.message, 'error', 5000))),

              tap(() => this.ui.hideLoading()),
            ),
          ),
        ),
      )
      .toPromise();
  }

  getPdfForm(e) {
    const settings = {};
    this.helper.getMtmTranspForm(this.modelId, settings).toPromise().finally();
  }
}
